import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
import { getShareHolderHistoryByCompanyandDate } from "../../../store/services/shareholder.service";
import ToggleButton from "react-toggle-button";
import { darkStyle } from "../../defaultStyles";
import Breadcrumb from "../../common/breadcrumb";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  generateExcel,
  getvalidDateDMonthY,
  getvalidDateDMY,
  listCrud,
  numberWithCommas,
} from "../../../../src/utilities/utilityFunctions";
import styled from "styled-components";

import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { Modal, ModalHeader, ModalBody, UncontrolledTooltip } from "reactstrap";
import * as _ from "lodash";
import AddShareholder from "./addShareholders";
import EditShareholder from "./editShareholder";
import ViewShareholder from "./viewShareholder";
import ShareCertificate from "./shareCertificateByFolio";
import {
  company_setter,
  investor_setter,
} from "../../../services/dropdown.service";
import {
  generateRegex,
  getFoundObject,
} from "../../../utilities/utilityFunctions";
import ReactPaginate from "react-paginate";
import { DashboardContext } from "../../../context/context";
import sixteen from "../../../assets/images/user/16.png";
import Spinner from "../../common/spinner";
import {
  getInvestorRequest,
  getInvestors,
} from "../../../store/services/investor.service";
import { getShareholders } from "../../../store/services/shareholder.service";
import { getCompanyById } from "../../../store/services/company.service";
import Collapse from "react-bootstrap/Collapse";
import { getPaginatedShareholdersByCompanyService } from "../../../store/services/shareholder.service";
import { FaChevronRight } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa";
import InvestorShareholding from "./investorShareholding";

export default function ShareholderListing() {
  const mixLayout = useSelector((state) => state?.Customizer?.mix_layout);
  const onHoverClass =
    mixLayout === "dark-body-only" ||
      mixLayout === "dark-only" ||
      mixLayout === "dark-sidebar-body-mix"
      ? "onHover-dark"
      : "onHover";
  const baseEmail = sessionStorage.getItem("email") || "";
  const [company_code, setCompany_code] = useState(
    sessionStorage.getItem("company_code") || ""
  );
  // const features = useSelector((data) => data.Features).features;
  const [collapse, setCollapse] = useState(false);
  const [crudFeatures, setCrudFeatures] = useState([true, true, true, true]);
  const [search, setSearch] = useState("");
  const [underSearch, setUnderSearch] = useState("");
  const [shareholders, setShareholders] = useState([]);
  const [active, setActive] = useState(true);
  const [companyNameSearch, setCompanyNameSearch] = useState(false);
  const [folioNoSearch, setFolioNoSearch] = useState(false);
  const [createdAtSearch, setCreatedAtSearch] = useState(false);
  const [historyDate, setHistoryDate] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [shareholdingHistory, setShareholdingHistory] = useState([]);
  const [historyLoading, setHistoryLoading] = useState(false);
  const [shareho, setshareho] = useState();
  const [shareholderNameSearch, setShareholderNameSearch] = useState(true);
  const [serachedShareholders, setSerachedShareholders] = useState([]);
  const [data, setData] = useState([]);
  const [viewAddPage, setViewAddPage] = useState(false);
  const [viewEditPage, setViewEditPage] = useState(false);
  const [viewFlag, setViewFlag] = useState(false);
  const [company_options, setCompany_options] = useState([]);
  const [investor_options, setInvestor_options] = useState([]);
  const [folio_number, setFolioNumber] = useState("");
  const [certificatesFlag, setCertificatesFlag] = useState(false);
  const [criteria, setCriteria] = useState("");
  const [currentPage, setCurrentPage] = useState();
  const [nextPage, setNextPage] = useState(1);
  const [prevPage, setPrevPage] = useState();
  const [hasNextPage, setHasNextPage] = useState();
  const [hasPrevPage, setHasPrevPage] = useState();
  const [totalPages, setTotalPages] = useState();
  const [totalRecords, setTotalRecords] = useState();
  const [crudOperations, setCrudOperations] = useState(
    JSON.parse(sessionStorage.getItem("features"))[0]
      ?.children.find((x) => x.feature == "Shareholding")
      ?.children.find((y) => y.feature == "ShareholdingListing")?.crud
  );
  const [viewShareholdingFlag, setViewShareholdingFlag] = useState(false);

  // useEffect(() => {
  //   if (features.length !== 0) setCrudFeatures(listCrud(features));
  // }, [features]);
  const borderRadiusStyle = { borderRadius: 2 };

  // Selector STARTS
  // const shareholders_data = useSelector((data) => data.Shareholders);
  // const { company_data, company_data_loading } = useSelector(
  //   (data) => data.Company
  // );
  // const investors = useSelector((data) => data.Investors);

  // const [investors, setInvestors] = useState([]);
  // const [investors_dropdown, setInvestors_Dropdown] = useState([]);
  const [shareholders_data, setShareholder_Data] = useState([]);
  const [company_data, setCompany_Data] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);

  const [company_data_loading, setCompany_Data_Loading] = useState(false);
  const [shareholders_data_loading, setShareholders_Data_Loading] =
    useState(false);
    const [selectedInvestor, setSelectedInvestor] = useState("");
    const [shareholdingCNIC, setSharehloldingCNIC] = useState("");
    const [shareholdingNTN, setSharehloldingNTN] = useState("");
  // const [investors_dropdown_loading, setInvestors_Dropdown_Loading] =
  //   useState(false);
  // Selector ENDS
  // let history = useHistory();
  // useEffect(() => {
  //   if (
  //     shareholders_data.length !== 0
  //     // ||
  //     // shareholders_data.inactive_shareholders_data.length !== 0
  //   ) {
  //     // !active
  //     //   ? setShareholders(shareholders_data)
  //     //   : setShareholders(shareholders_data.inactive_shareholders_data);

  //       setShareholders(shareholders_data);
  //   }
  // }, [
  //   active,
  //   // shareholders_data.shareholders_data,
  //   // shareholders_data.inactive_shareholders_data,
  //   shareholders_data
  // ]);

  const getCompany = async () => {
    try {
      setCompany_Data_Loading(true);
      const response = await getCompanyById(baseEmail, company_code);
      if (response.status == 200) {
        setCompany_Data(response.data.data);
      } else {
        setCompany_Data([]);
      }
      setCompany_Data_Loading(false);
    } catch (error) {
      setCompany_Data_Loading(false);
      if (error.response != undefined) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
    setCompany_Data_Loading(false);
  };

  // const getAllInvestors = async () => {
  //   try {
  //     setInvestors_Dropdown_Loading(true);
  //     const response = await getInvestors(baseEmail);
  //     if (response.status == 200) {
  //       const investors_dropdown = response.data.data.map((item) => {
  //         let label = `${item.investor_id} (${item.investor_name}) `;
  //         return { label: label, value: item.investor_id };
  //       });
  //       setInvestors_Dropdown(investors_dropdown);
  //       // setInvestors(response.data.data);
  //     } else {
  //       setInvestors_Dropdown([]);
  //     }
  //     setInvestors_Dropdown_Loading(false);
  //   } catch (error) {
  //     setInvestors_Dropdown_Loading(false);
  //     if (error.response !== undefined) {
  //       toast.error(error.response.data.message);
  //     } else {
  //       toast.error(error.message);
  //     }
  //   }
  //   setInvestors_Dropdown_Loading(false);
  // };
  //previous api function commented
  // const getShareholdersByCompany = async() =>{
  //   try{
  //     setShareholders_Data_Loading(true);
  //     const response = await getShareholders(baseEmail,company_code);
  //     if(response.status==200){
  //       // const shareholders_dropdown = response.data.data.map((item) => {
  //       //   let label = `${item.folio_number} (${item.shareholder_name}) `;
  //       //   return { label: label, value: item.folio_number };
  //       // });
  //       // setShareholders_dropdown(shareholders_dropdown);
  //       setShareholder_Data(response.data.data);
  //     }else{
  //       setShareholder_Data([]);
  //     }
  //     setShareholders_Data_Loading(false);
  //   }catch(error){
  //     setShareholders_Data_Loading(false);
  //     if(error.response!=undefined){
  //       toast.error(error.response.data.message);
  //     }else{
  //       toast.error(error.message);
  //     }
  //   }
  //   setShareholders_Data_Loading(false);
  // }
  //previous api function commented top
  const getSearchHolderbyCompanyService = async (page) => {
    const email = sessionStorage.getItem("email");
    const companyCode = sessionStorage.getItem("company_code");
    try {
      setShareholders_Data_Loading(true);
      const searchCriteria =
        criteria == "folio" ? `${company_code}-` + search : search;
      const response = await getPaginatedShareholdersByCompanyService(
        email,
        companyCode,
        page,
        searchCriteria,
        criteria,
        active
      );
      if (response.status == 200) {
        // console.log("RESPONSE => ", response);
        // const shareholders_dropdown = response.data.data.map((item) => {
        //   let label = `${item.folio_number} (${item.shareholder_name}) `;
        //   return { label: label, value: item.folio_number };
        // });
        // setShareholders_dropdown(shareholders_dropdown);

        setShareholder_Data(response.data.data.docs);
        setHasNextPage(response.data.data.hasNextPage);
        setHasPrevPage(response.data.data.hasPrevPage);
        setNextPage(response.data.data.nextPage);
        setPrevPage(response.data.data.prevPage);
        setCurrentPage(response.data.data.page);
        setTotalPages(response.data.data.totalPages);
        setTotalRecords(response.data.data.totalDocs);
        setShareholders_Data_Loading(false);
      } else {
        setShareholder_Data([]);
        setShareholders_Data_Loading(false);
      }
    } catch (error) {
      setShareholders_Data_Loading(false);
      if (error.response != undefined) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };
  useEffect(() => {
    getCompany();
    // getAllInvestors();
    getSearchHolderbyCompanyService(nextPage);
    // getShareholdersByCompany();
  }, [company_code]);

  useEffect(() => {
    const handleStorageChange = () => {
      // window.location.reload();
      setCompany_code(sessionStorage.getItem("company_code"));
      // dashboardFunctionality();
      // getShareHolerGraphData();
      return;
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    getSearchHolderbyCompanyService(nextPage);
  }, [active]);

  useEffect(() => {
    if (criteria === "") {
      getSearchHolderbyCompanyService();
    }
  }, [criteria]);

  useEffect(() => {
    if (!setViewShareholdingFlag) {
      setSharehloldingCNIC("");
      setSharehloldingNTN("");
      setSelectedInvestor("");
    }
  }, [setViewShareholdingFlag]);

  // useEffect(async () => {
  //   document.title = "Share Holders";
  //   try {
  //     setCompany_options(await company_setter());
  //     setInvestor_options(await investor_setter());
  //   } catch (err) {
  //     toast.error(`${err.response.data.message}`);
  //   }
  // }, []);
  /*  ---------------------  */
  /*  Pagination Code Start  */
  /*  ---------------------  */
  const shareholderPerPage = 10;
  const pagesVisited = pageNumber * shareholderPerPage;
  const totalnumberofPages = 100;
  const displayShareholdersPerPage =
    !underSearch && shareholders_data.length !== 0
      ? shareholders_data
        // .sort((a, b) => {
        //   if (
        //     new Date(b.create_at).getTime() < new Date(a.create_at).getTime()
        //   )
        //     return -1;
        //   if (
        //     new Date(b.create_at).getTime() > new Date(a.create_at).getTime()
        //   )
        //     return 1;
        //   return 0;
        // })
        // .slice(pagesVisited, pagesVisited + shareholderPerPage)
        .map((shareholder, i) => (
          <tr className={onHoverClass} key={i}>
            <td>
              {shareholder.folio_number.replace(`${company_code}-`, "")}
            </td>
            {/* <td>{shareholder.symbol}</td> */}
            <td>{shareholder.shareholder_name}</td>
            <td>{shareholder.shareholder_id || ""}</td>
            {/* <td>
                {
                  companies.companies_data.find(
                    (comp) => comp.code === shareholder.company_code
                  )?.company_name
                }
              </td> */}
            <td className="text-right">
              {numberWithCommas(shareholder.electronic_shares || 0)}
            </td>
            <td className="text-right">
              {numberWithCommas(shareholder.physical_shares || 0)}
            </td>
            <td className="text-right">
              {numberWithCommas(
                Number(shareholder?.electronic_shares || "0") +
                Number(shareholder?.physical_shares || "0") || 0
              )}
            </td>
            {(crudOperations[1] || crudOperations[2]) && (
              <td>
                <div className="d-flex flex-wrap align-items-center ">
                 {JSON.parse(sessionStorage.getItem("all_companies") || "[]").length > 1 && <> <svg
                    style={{
                      cursor: "pointer",
                      width: "22px",
                      height: "22px",
                      outline: "none",
                    }}
                    onClick={() => {
                      if (shareholder?.cnic) {
                        setSharehloldingCNIC(shareholder?.cnic);
                        setSharehloldingNTN("");
                      } else if (shareholder?.ntn) {
                        setSharehloldingNTN(shareholder?.ntn);
                        setSharehloldingCNIC("");
                      }
                      setSelectedInvestor(shareholder);
                      setViewShareholdingFlag(true);
                    }}
                    id="shareholdingView"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                  >
                    <path
                      fill="#fec40e"
                      d="M16 3c-.625 0-1.246.21-1.781.594L12.563 4.75 10.655 5h-.031l-.031.031A3.394 3.394 0 008.03 7.594L8 7.625v.031l-.25 1.938-1.156 1.5-.032.031v.031c-.699 1.117-.73 2.559.032 3.625l1.187 1.656.313 1.72-3.219 4.905-1.031 1.532h4.781l1.156 2.687L10.5 29l1.031-1.563 3.156-4.75c.848.348 1.805.38 2.626 0l3.156 4.75L21.5 29l.719-1.719 1.156-2.687h4.781l-1.031-1.532L24 18.313l.25-1.875 1.156-1.656.032-.031v-.031c.699-1.117.73-2.528-.032-3.594L24.25 9.469l-.375-1.875h.031c-.004-.024-.027-.04-.031-.063-.18-1.308-1.215-2.37-2.531-2.531h-.032l-1.875-.25-1.656-1.156A3.074 3.074 0 0016 3zm0 2.031c.23 0 .457.07.625.188L18.406 6.5l.219.156.25.032L21.063 7h.03c.45.05.762.363.813.813v.062l.407 2.219.03.219.157.187 1.281 1.781c.239.332.27.895-.031 1.375l-1.406 1.969-.032.25L22 18.063v.03a.994.994 0 01-.156.438l-.063.032v.03a.88.88 0 01-.593.313h-.063l-2.281.407-.25.03-.188.157-1.781 1.281c-.332.239-.926.27-1.406-.031l-1.625-1.25-.188-.156-.281-.032L10.937 19h-.03a.885.885 0 01-.688-.438.99.99 0 01-.125-.375v-.062l-.406-2.281-.032-.25-.156-.188-1.281-1.781c-.239-.332-.27-.926.031-1.406l1.25-1.625.156-.188.031-.281.282-2.094c.004-.015.027-.015.031-.031a1.394 1.394 0 011-1c.016-.004.016-.027.031-.031l2.094-.282.25-.03.219-.157 1.781-1.281c.168-.117.395-.188.625-.188zm6.906 15.219l1.532 2.344H22.03l-.25.625-.687 1.593-2.125-3.25.468-.343 1.97-.344v.031c.023-.004.038-.027.062-.031a2.97 2.97 0 001.437-.625zm-13.812.031a2.85 2.85 0 001.562.719h.031l1.907.25.437.344-2.125 3.218-.687-1.593-.25-.625H7.563z"
                    ></path>
                  </svg> <UncontrolledTooltip placement="top" target="shareholdingView">
                    {"View Shareholding"}
                  </UncontrolledTooltip> </>}
                 
                  {crudOperations[1] && (
                    <>
                      <i
                        className="fa fa-eye"
                        style={{
                          width: 35,
                          fontSize: 16,
                          padding: 11,
                          color: "#4466F2",
                          cursor: "pointer",
                        }}
                        id="shareholderView"
                        onClick={() => {
                          const obj = JSON.parse(JSON.stringify(shareholder));
                          obj.company_code = {
                            label: company_data?.company_name,
                            value: company_data?.code,
                          };
                          // obj.shareholder_id = getFoundObject(
                          //   // investors.investors_dropdown,
                          //   investors_dropdown,
                          //   obj.shareholder_id
                          // );
                          obj.shareholder_id = obj.shareholder_id;
                          // for modal
                          setViewFlag(true);
                          sessionStorage.setItem(
                            "selectedShareholder",
                            JSON.stringify(obj)
                          );
                        }}
                      ></i>
                      <UncontrolledTooltip
                        placement="top"
                        target="shareholderView"
                      >
                        {"View Shareholder's Detail"}
                      </UncontrolledTooltip>
                    </>
                  )}

                  {crudOperations[1] &&
                    shareholder.cdc_key.toUpperCase() === "NO" && (
                      <>
                        <i
                          className="fa fa-certificate"
                          style={{
                            width: 35,
                            fontSize: 16,
                            padding: 11,
                            color: "#4466F2",
                            cursor: "pointer",
                          }}
                          id="shareholderCertificates"
                          onClick={() => {
                            const obj = JSON.parse(JSON.stringify(shareholder));
                            setFolioNumber(obj.folio_number);
                            // for modal
                            setCertificatesFlag(true);
                          }}
                        ></i>
                        <UncontrolledTooltip
                          placement="top"
                          target="shareholderCertificates"
                        >
                          {"View Shareholder's Certficates"}
                        </UncontrolledTooltip>
                      </>
                    )}

                  {/* {crudOperations[2] && (
                    <>
                      <i
                        className="fa fa-pencil"
                        style={{
                          width: 35,
                          fontSize: 16,
                          padding: 11,
                          color: "#FF9F40",
                          cursor: "pointer",
                        }}
                        id="shareholderEdit"
                        onClick={() => {
                          const obj = JSON.parse(JSON.stringify(shareholder));
                          obj.company_code = getFoundObject(
                            companies.companies_dropdown,
                            obj.company_code
                          );
                          obj.shareholder_id = getFoundObject(
                            investors.investors_dropdown,
                            obj.shareholder_id
                          );
                          // for modal
                          setViewEditPage(true);
                          sessionStorage.setItem(
                            "selectedShareholder",
                            JSON.stringify(obj)
                          );
                        }}
                      ></i>
                      <UncontrolledTooltip
                        placement="top"
                        target="shareholderEdit"
                      >
                        {"Edit Shareholder's Detail"}
                      </UncontrolledTooltip>
                    </>
                  )} */}
                </div>
              </td>
            )}
          </tr>
        ))
      : serachedShareholders.length !== 0 &&
      serachedShareholders
        .sort((a, b) => {
          if (
            new Date(b.created_at).getTime() <
            new Date(a.created_at).getTime()
          )
            return -1;
          if (
            new Date(b.created_at).getTime() >
            new Date(a.created_at).getTime()
          )
            return 1;
          return 0;
        })
        .slice(pagesVisited, pagesVisited + shareholderPerPage)
        .map((shareholder, i) => (
          <tr key={i}>
            <td>
              {shareholder.folio_number.replace(`${company_code}-`, "")}
            </td>
            {/* <td>{shareholder.symbol}</td> */}
            <td>{shareholder.shareholder_name}</td>
            <td>{shareholder.shareholder_id}</td>
            {/* <td>
                {
                  companies.companies_data.find(
                    (comp) => comp.code === shareholder.company_code
                  )?.company_name
                }
              </td> */}
            <td className="text-right">
              {numberWithCommas(shareholder.electronic_shares)}
            </td>
            <td className="text-right">
              {numberWithCommas(shareholder.physical_shares)}
            </td>
            <td>{shareholder.cdc_key}</td>
            {(crudOperations[1] || crudOperations[2]) && (
              <td>
                {crudOperations[1] && (
                  <>
                    <i
                      className="fa fa-eye"
                      style={{
                        width: 35,
                        fontSize: 16,
                        padding: 11,
                        color: "#4466F2",
                        cursor: "pointer",
                      }}
                      id="shareholderView"
                      onClick={() => {
                        const obj = JSON.parse(JSON.stringify(shareholder));
                        obj.company_code = {
                          labe: company_data?.company_name,
                          value: company_data?.code,
                        };
                        // obj.shareholder_id = getFoundObject(
                        //   // investors.investors_dropdown,
                        //   investors_dropdown,
                        //   obj.shareholder_id
                        // );
                        obj.shareholder_id = obj.shareholder_id;
                        // for modal
                        setViewFlag(true);
                        sessionStorage.setItem(
                          "selectedShareholder",
                          JSON.stringify(obj)
                        );
                      }}
                    ></i>
                    <UncontrolledTooltip
                      placement="top"
                      target="shareholderView"
                    >
                      {"View Shareholder's Detail"}
                    </UncontrolledTooltip>
                  </>
                )}
                {/* {crudOperations[2] && (
                    <>
                      <i
                        className="fa fa-pencil"
                        style={{
                          width: 35,
                          fontSize: 16,
                          padding: 11,
                          color: "#FF9F40",
                          cursor: "pointer",
                        }}
                        id="shareholderEdit"
                        onClick={() => {
                          const obj = JSON.parse(JSON.stringify(shareholder));
                          obj.company_code = {
                            labe: company_data?.company_name,
                            value: company_data?.code,
                          };
                          obj.shareholder_id = getFoundObject(
                            // investors.investors_dropdown,
                            investors_dropdown,
                            obj.shareholder_id
                          );
                          // for modal
                          setViewEditPage(true);
                          sessionStorage.setItem(
                            "selectedShareholder",
                            JSON.stringify(obj)
                          );
                        }}
                      ></i>
                      <UncontrolledTooltip
                        placement="top"
                        target="shareholderEdit"
                      >
                        {"Edit Shareholder's Detail"}
                      </UncontrolledTooltip>
                    </>
                  )} */}

                {/* {crudOperations[1] && (
                  <>
                    <i
                      className="fa fa-certificate"
                      style={{
                        width: 35,
                        fontSize: 16,
                        padding: 11,
                        color: "#4466F2",
                        cursor: "pointer",
                      }}
                      id="shareholderCertificates"
                      onClick={() => {
                        const obj = JSON.parse(JSON.stringify(shareholder));
                        setFolioNumber(obj.folio_number);
                        // for modal
                        setCertificatesFlag(true)
                      }}
                    ></i>
                    <UncontrolledTooltip
                      placement="top"
                      target="shareholderCertificates"
                    >
                      {"View Shareholder's Certficates"}
                    </UncontrolledTooltip>
                  </>
                )} */}
              </td>
            )}
          </tr>
        ));
  const pageCount = !underSearch
    ? Math.ceil(shareholders_data.length / shareholderPerPage)
    : serachedShareholders.length &&
    Math.ceil(serachedShareholders.length / shareholderPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  /*  ---------------------  */
  /*  Pagination Code Ended  */
  /*  ---------------------  */
  //handle previos function commented
  // const handleSearchChange = (e) => {
  //   setSearch(e.target.value);
  //   setUnderSearch(e.target.value);
  //   if (e.target.value.length > 0 && shareholdingHistory.length === 0) {
  //     if (shareholders_data.length !== 0) {
  //       if (folioNoSearch) {
  //         setSerachedShareholders(
  //           shareholders_data.filter((holders) => {
  //             return holders.folio_number.match(generateRegex(e.target.value));
  //           })
  //         );
  //       }
  //       // if (companyNameSearch) {
  //       //   setSerachedShareholders(
  //       //     shareholders.filter((comp) => {
  //       //       return companies.companies_data
  //       //         .find((com) => comp.company_code === com.code)
  //       //         ?.company_name.match(generateRegex(e.target.value));
  //       //     })
  //       //   );
  //       // }
  //       if (shareholderNameSearch) {
  //         setSerachedShareholders(
  //           shareholders_data.filter((holders) => {
  //             return holders.shareholder_name.match(
  //               generateRegex(e.target.value)
  //             );
  //           })
  //         );
  //       }
  //       if (createdAtSearch) {
  //         setSerachedShareholders(
  //           shareholders_data.filter(
  //             (holders) => holders.created_at === e.target.value
  //           )
  //         );
  //       }
  //     }
  //   }
  //   if (e.target.value.length > 0 && shareholdingHistory.length !== 0) {
  //     if (shareholdingHistory?.length !== 0) {
  //       if (folioNoSearch) {
  //         setSerachedShareholders(
  //           shareholdingHistory.filter((holders) => {
  //             return holders.folio_number.match(generateRegex(e.target.value));
  //           })
  //         );
  //       }
  //       // if (companyNameSearch) {
  //       //   setSerachedShareholders(
  //       //     shareholdingHistory.filter((comp) => {
  //       //       return companies.companies_data
  //       //         .find((com) => comp.company_code === com.code)
  //       //         ?.company_name.match(generateRegex(e.target.value));
  //       //     })
  //       //   );
  //       // }
  //       if (shareholderNameSearch) {
  //         setSerachedShareholders(
  //           shareholdingHistory.filter((holders) => {
  //             return holders.shareholder_name.match(
  //               generateRegex(e.target.value)
  //             );
  //           })
  //         );
  //       }
  //       if (createdAtSearch) {
  //         setSerachedShareholders(
  //           shareholdingHistory.filter(
  //             (holders) => holders.created_at === e.target.value
  //           )
  //         );
  //       }
  //     }
  //   }
  // };
  //above code commented

  const handleSearch = async (e) => {
    e.preventDefault();
    if (criteria == "" || !criteria) {
      return toast.error("Please select search criteria!");
    }
    if (!search || search == "") {
      return toast.error("Enter value for searching");
    }
    // let response;
    // setIsLoading(true);
    if (criteria !== "" && criteria) {
      await getSearchHolderbyCompanyService(1);
      // setIsLoading(false)
      // response = await getPaginatedRecordsService(
      //   baseEmail,
      //   "1",
      //   search,
      //   criteria
      // );
      // if (response.status === 200) {
      //   setHasNextPage(response.data.data.hasNextPage);
      //   setHasPrevPage(response.data.data.hasPrevPage);
      //   setNextPage(response.data.data.nextPage);
      //   setPrevPage(response.data.data.prevPage);
      //   setCurrentPage(response.data.data.page);
      //   setTotalPages(response.data.data.totalPages);
      //   setTotalRecords(response.data.data.totalDocs);

      //   const parents = response.data.data.docs ? response.data.data.docs : [];
      //   setIsLoading(false);
      // } else {
      //   return toast.error(response.data.message);
      // }
    }
  };

  const handleHistorySearch = () => {
    const getShareholdingHistory = async () => {
      try {
        setHistoryLoading(true);
        const response = await getShareHolderHistoryByCompanyandDate(
          baseEmail,
          selectedCompany,
          historyDate
        );
        if (response.status === 200) {
          setShareholdingHistory(
            JSON.parse(response.data.data[0].shareholders)
          );
          setUnderSearch("searched");
          setHistoryLoading(false);
        }
      } catch (error) {
        !!error?.response?.data?.message
          ? toast.error(error?.response?.data?.message)
          : console.log("Shareholding History Not Found");
        setUnderSearch("");
        setHistoryLoading(false);
      }
    };
    if (historyDate && selectedCompany) {
      getShareholdingHistory();
    }
  };
  const headings = [["Holding as on:", getvalidDateDMonthY(historyDate)]];
  const columns =
    underSearch &&
    _.keys(
      _.assignIn(
        { folio_type: "PHYSICAL", total_percentage: "20" },
        _.omit(shareholdingHistory[shareholdingHistory.length - 1], [
          "doc_type",
          "gateway_code",
          "joint_holders",
          "cdc_key",
        ])
      )
    ).map((e) => e.toUpperCase().replaceAll("_", " "));

  const [screen, setScreen] = useState();
  const tableWrapperRef1 = useRef(null);
  const [isScrollable, setIsScrollable] = useState([false]);

  useEffect(() => {
    const handleResize = () => {
      setScreen(window?.outerWidth);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const tempArr = [tableWrapperRef1];
      const newIsScrollable = tempArr?.map((ref) => {
        if (ref.current) {
          const tableWidth = ref.current.scrollWidth;
          const wrapperWidth = ref.current.clientWidth;
          return tableWidth > wrapperWidth;
        }
        return false;
      });
      setIsScrollable(newIsScrollable);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleScrollLeft = (tableRef) => {
    if (tableRef.current) {
      tableRef.current.scrollTo({
        left: tableRef.current.scrollLeft - 200,
        behavior: "smooth",
      });
    }
  };

  const handleScrollRight = (tableRef) => {
    if (tableRef.current) {
      tableRef.current.scrollTo({
        left: tableRef.current.scrollLeft + 200,
        behavior: "smooth",
      });
    }
  };

  return (
    <Fragment>
      {/* bread crumb at the top of the page */}
      {/* <div className="d-flex justify-content-between">
        <div>
          <Breadcrumb
            title="Shareholdings Listing"
            parent="Shareholdings"
            hideBookmark
          />
        </div>
      </div> */}
      {/* Add Modal */}
      <Modal toggle={() => {
            setViewAddPage(false);
          }} isOpen={viewAddPage} show={viewAddPage.toString()} size="xl">
        <ModalHeader
          toggle={() => {
            setViewAddPage(false);
          }}
        >
          Add Shareholder
        </ModalHeader>
        <ModalBody>
          <AddShareholder setViewAddPage={setViewAddPage} />
        </ModalBody>
      </Modal>
      {/* Edit Modal */}
      <Modal toggle={() => {
            setViewEditPage(false);
          }} isOpen={viewEditPage} show={viewEditPage.toString()} size="xl">
        <ModalHeader
          toggle={() => {
            setViewEditPage(false);
          }}
        >
          Shareholder Edit
        </ModalHeader>
        <ModalBody>
          <EditShareholder setViewEditPage={setViewEditPage} />
        </ModalBody>
      </Modal>
      {/* View Modal */}
      <Modal  toggle={() => {
            setViewFlag(false);
          }} isOpen={viewFlag} show={viewFlag.toString()} size="xl">
        <ModalHeader
          toggle={() => {
            setViewFlag(false);
          }}
        >
          Shareholder View
        </ModalHeader>
        <ModalBody>
          <ViewShareholder />
        </ModalBody>
      </Modal>
      {/* view share certificates by folio no */}
      <Modal
      toggle={() => {
        setCertificatesFlag(false);
      }}
        isOpen={certificatesFlag}
        show={certificatesFlag.toString()}
        size="xl"
      >
        <ModalHeader
          toggle={() => {
            setCertificatesFlag(false);
          }}
        >
          Shareholder Certificates
        </ModalHeader>
        <ModalBody>
          <ShareCertificate folio_number={folio_number} />
        </ModalBody>
      </Modal>

      {/* View Shareholding Modal */}
      <Modal
        toggle={() => {
          setViewShareholdingFlag(false);
        }}
        isOpen={viewShareholdingFlag}
        show={viewShareholdingFlag.toString()}
        size="xl"
      >
        <ModalHeader
          toggle={() => {
            setViewShareholdingFlag(false);
          }}
        >
          {selectedInvestor?.investor_name} (
          {selectedInvestor?.cnic || selectedInvestor?.ntn}) Shareholding
          Details
        </ModalHeader>
        <ModalBody>
          <InvestorShareholding
            selectedInvestor={selectedInvestor}
            setSelectedInvestor={setSelectedInvestor}
            shareholdingCNIC={shareholdingCNIC}
            setSharehloldingCNIC={setSharehloldingCNIC}
            shareholdingNTN={shareholdingNTN}
            setSharehloldingNTN={setSharehloldingNTN}
          />
        </ModalBody>
      </Modal>

      <div className="container-fluid pt-3">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header ">
                <WrapperForResponsive>
                  <div
                    className={
                      screen <= 500
                        ? "d-flex flex-column text-center justify-content-center align-items-center"
                        : "d-flex justify-content-between align-items-center"
                    }
                  >
                    <h5>Shareholders</h5>
                    {screen <= 500 ? (
                      <hr
                        style={{
                          height: "1px",
                          width: "100%",
                        }}
                      ></hr>
                    ) : (
                      ""
                    )}

                    {/* {crudOperations[0] && (
                    <div className="btn-group"> */}
                    {/* <button
                        className="btn btn-success btn-sm"
                        disabled={
                          shareholdingHistory.length === 0 ||
                          !historyDate ||
                          !selectedCompany
                        }
                        onClick={(e) => {
                          generateExcel(
                            `Shareholding Report ${getvalidDateDMY(
                              new Date()
                            )}`,
                            "Shareholding Report",
                            "Report",
                            "Report",
                            "DCCL",
                            headings,
                            columns,
                            shareholdingHistory.map((data) => ({
                              folio_type:
                                data.cdc_key === "YES"
                                  ? "Electronic"
                                  : "Physical",
                              total_percentage:
                                (companies.companies_data.find(
                                  (comp) => comp.code === selectedCompany
                                )?.outstanding_shares /
                                  (parseInt(data.electronic_shares) +
                                    parseInt(data.physical_shares))) *
                                100,
                              ..._.omit(data, [
                                "doc_type",
                                "gateway_code",
                                "joint_holders",
                                "cdc_key",
                              ]),
                            }))
                          );
                        }}
                      >
                        <i className="fa fa-file-excel-o mr-1"></i>Generate
                        Report
                      </button> */}
                    {/* <button
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                          // for modal
                          setViewAddPage(true);
                        }}
                      >
                        <i className="fa fa-plus mr-1"></i> Add Shareholder
                      </button>
                    </div>
                  )} */}

                    {crudFeatures[1] && (
                      <div className={screen <= 500 ? "col-12 px-0" : ""}>
                        <button
                          className={
                            screen <= 500
                              ? "col-12 text-center btn btn-info btn-sm d-flex justify-content-center align-items-center "
                              : "btn btn-info btn-sm float-right d-flex align-items-center "
                          }
                          onClick={() => {
                            setCollapse(!collapse);
                          }}
                        >
                          Filter &nbsp;
                          <i
                            class={
                              collapse === false
                                ? "fa fa-angle-down float-right"
                                : "fa fa-angle-up float-right"
                            }
                            aria-controls="collapse-search"
                            aria-expanded={collapse}
                            title={
                              collapse === false
                                ? "View Search"
                                : "Close Search"
                            }
                          ></i>
                        </button>
                      </div>
                    )}
                  </div>
                </WrapperForResponsive>
              </div>

              {crudOperations[1] && (
                <Collapse in={collapse}>
                  <div id="collapse-search">
                    <div
                      className={
                        screen <= 500 ? "col-12 px-0 mt-2" : "row mt-2"
                      }
                      style={{ width: "100%" }}
                    >
                      <form
                        style={{ flexWrap: "wrap" }}
                        className={
                          screen <= 500
                            ? "d-flex justify-content-center align-items-center col-md-12 px-0 mt-0"
                            : "d-flex justify-content-start align-items-center col-md-12 mt-0"
                        }
                        onSubmit={(e) => handleSearch(e)}
                      >
                        <div className=" col-md-4">
                          <div className="form-group px-1">
                            {/* <label htmlFor="company_type">Search Criteria</label> */}
                            <select
                              name="search_criteria"
                              className={`form-control`}
                              onChange={(e) => {
                                setSearch("");
                                setCriteria(e.target.value);
                              }}
                            >
                              <option value="">Select Criteria</option>
                              <option value="folio">Folio </option>
                              <option value="name">Shareholder Name</option>
                              <option value="cnic">CNIC</option>
                              <option value="ntn">NTN</option>
                            </select>
                          </div>
                        </div>
                        <div className=" col-md-4 ">
                          <div className="form-group px-1">
                            <input
                              id="searchTransaction"
                              className="form-control"
                              type={`${createdAtSearch ? "date" : "text"}`}
                              placeholder={
                                criteria == "" || !criteria
                                  ? `Select Criteria First`
                                  : criteria == "folio"
                                    ? `Search by Folio`
                                    : criteria == "name"
                                      ? `Search by ShareHolder Name`
                                      : criteria == "cnic"
                                        ? `Search by CNIC`
                                        : `Search by NTN`
                              }
                              value={search}
                              onChange={(e) => setSearch(e.target.value)}
                              disabled={!criteria || criteria.trim() === ""}
                            />
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div
                            className={screen <= 500 ? "px-1" : "form-group"}
                          >
                            <button
                              className={
                                screen <= 500
                                  ? "col-12 btn btn-secondary btn-sm my-1"
                                  : "btn btn-secondary btn-sm my-1"
                              }
                              disabled={!criteria}
                            >
                              <span>{"Search"}</span>
                            </button>
                          </div>
                        </div>

                        <div
                          className={
                            screen <= 500
                              ? "mt-2 col-12 d-flex justify-content-center align-items-center"
                              : "col-md-2"
                          }
                        >
                          <div className="form-group">
                            <label>Inactive</label>
                            <ToggleButton
                              value={active}
                              thumbStyle={borderRadiusStyle}
                              trackStyle={borderRadiusStyle}
                              onToggle={(e) => {
                                setActive(!active);
                              }}
                            />
                          </div>
                        </div>
                      </form>

                      {/* <div className="col-md-3">
                    <div className="form-group">
                      <input
                        id="searchTransaction"
                        className="form-control"
                        type={`${createdAtSearch ? "date" : "text"}`}
                        placeholder={`Enter ${
                          folioNoSearch
                            ? "Folio Number"
                            : shareholderNameSearch
                            ? "Shareholder Name"
                            : "Company Name"
                        }`}
                        value={search}
                        onChange={(e) => {
                          handleSearchChange(e);
                        }}
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target="searchTransaction"
                      >
                        Enter
                        {folioNoSearch
                          ? " Folio Number"
                          : shareholderNameSearch
                          ? " Shareholder Name"
                          : " Company Name"}
                      </UncontrolledTooltip>
                    </div>
                  </div> */}

                      {/* <div className="col-md-2">
                    <span className="checkbox checkbox-info">
                      <input
                        id="created_at"
                        type="checkbox"
                        checked={createdAtSearch}
                        onChange={(e) => {
                          // setCompanyNameSearch(false);
                          setShareholderNameSearch(false);
                          setCreatedAtSearch(true);
                          setFolioNoSearch(false);
                        }}
                      />
                      <label htmlFor="created_at">Date</label>
                    </span>
                  </div>
                  <div className="col-md-2">
                    <span className="checkbox checkbox-info">
                      <input
                        id="parent_company"
                        type="checkbox"
                        checked={shareholderNameSearch}
                        onChange={(e) => {
                          setShareholderNameSearch(true);
                          // setCompanyNameSearch(false);
                          setCreatedAtSearch(false);

                          setFolioNoSearch(false);
                        }}
                      />
                      <label htmlFor="parent_company">Shareholder Name</label>
                    </span>
                  </div> */}
                      {/* <div className="col-md-2"> */}
                      {/* <span className="checkbox checkbox-success ">
                      <input
                        id="company_name"
                        type="checkbox"
                        checked={companyNameSearch}
                        onChange={(e) => {
                          setCompanyNameSearch(true);
                          setFolioNoSearch(false);
                          setCreatedAtSearch(false);
                          setShareholderNameSearch(false);
                        }}
                      />
                      <label htmlFor="company_name">Company Name</label>
                    </span> */}
                      {/* </div> */}
                      {/* <div className="col-md-2"> */}
                      {/* <span className="checkbox checkbox-info">
                      <input
                        id="company_code"
                        type="checkbox"
                        checked={folioNoSearch}
                        onChange={(e) => {
                          setCompanyNameSearch(false);
                          setShareholderNameSearch(false);
                          setCreatedAtSearch(false);

                          setFolioNoSearch(true);
                        }}
                      />
                      <label htmlFor="company_code">Folio Number</label>
                    </span> */}
                      {/* </div> */}
                      {/* <div className="col-md-3">
                        <div
                          className="form-group"
                          style={{ paddingLeft: "15px" }}
                        >
                          <br />
                          <label>Show Inactive Shareholders </label>
                          <br />
                          <ToggleButton
                            value={active}
                            thumbStyle={borderRadiusStyle}
                            trackStyle={borderRadiusStyle}
                            onToggle={(e) => {
                              setActive(!active);
                            }}
                          />
                        </div>
                      </div> */}
                    </div>
                  </div>
                </Collapse>
              )}

              {crudOperations[1] &&
                (shareholders_data_loading === true ||
                  // investors_dropdown_loading === true ||
                  company_data_loading === true) && <Spinner />}
              {crudOperations[1] &&
                shareholders_data_loading === false &&
                // investors_dropdown_loading === false &&
                company_data_loading === false &&
                shareholders_data.length !== 0 && (
                  // investors_dropdown.length !== 0 &&
                  <TableStyle>
                    <div
                      style={{
                        position: "relative",
                      }}
                      className="px-3 pb-3"
                    >
                      {isScrollable[0] && (
                        <ScrollIconStyle>
                          <FaChevronLeft
                            style={{
                              position: "absolute",
                              left: "1px",
                              bottom: "11px",
                              color: "grey",
                              cursor: "pointer",
                            }}
                            className="left_Icon"
                            onClick={() => {
                              handleScrollLeft(tableWrapperRef1);
                            }}
                          />
                        </ScrollIconStyle>
                      )}
                      <div ref={tableWrapperRef1} className=" table-responsive">
                        <table className="table table-striped ">
                          <thead>
                            <tr>
                              <th>Folio #</th>
                              {/* <th>Symbol</th> */}
                              <th>Shareholder Name</th>
                              <th>Shareholder CNIC/NTN</th>
                              {/* <th>Company</th> */}
                              <th className="text-right">Electronic Shares</th>
                              <th className="text-right">Physical Shares</th>
                              <th className="text-right">Total Holding</th>
                              {(crudOperations[1] || crudOperations[2]) && (
                                <th>Action</th>
                              )}
                            </tr>
                          </thead>

                          <tbody>{displayShareholdersPerPage}</tbody>
                        </table>
                        <center className="d-flex justify-content-center py-3">
                          <nav className="pagination">
                            {hasPrevPage && (
                              <button
                                className="btn btn-primary btn-sm mx-1"
                                onClick={() =>
                                  getSearchHolderbyCompanyService(prevPage)
                                }
                              >
                                <span>{"Prev"}</span>
                              </button>
                            )}
                            {hasNextPage && (
                              <button
                                className="btn btn-secondary btn-sm mx-1"
                                onClick={() =>
                                  getSearchHolderbyCompanyService(nextPage)
                                }
                              >
                                <span>{"Next"}</span>
                              </button>
                            )}
                          </nav>
                        </center>
                        <p className="align-content-center text-center mx-2">
                          Page {currentPage} of {totalPages}
                        </p>
                        <p className="text-right mx-2">
                          {totalRecords} Records
                        </p>
                        {/* <center className="d-flex justify-content-center py-3">
                      <nav className="pagination">
                        <ReactPaginate
                          previousLabel="Previous"
                          nextLabel="Next"
                          pageCount={pageCount}
                          onPageChange={changePage}
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={3}
                          containerClassName={"pagination"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          disabledClassName={"disabled"}
                          pageLinkClassName={"page-link"}
                          pageClassName={"page-item"}
                          activeClassName={"page-item active"}
                          activeLinkClassName={"page-link"}
                        />
                      </nav>
                    </center> */}
                      </div>
                      {isScrollable[0] && (
                        <ScrollIconStyle>
                          <FaChevronRight
                            style={{
                              position: "absolute",
                              right: "1px",
                              bottom: "11px",
                              color: "grey",
                              cursor: "pointer",
                            }}
                            className="right_Icon"
                            onClick={() => {
                              handleScrollRight(tableWrapperRef1);
                            }}
                          />
                        </ScrollIconStyle>
                      )}
                    </div>
                  </TableStyle>
                )}
              {crudOperations[1] &&
                shareholders_data_loading === false &&
                // investors_dropdown_loading === false &&
                !company_data_loading &&
                shareholders_data.length === 0 && (
                  // investors_dropdown.length === 0 &&
                  <p className="text-center">
                    <b>Shareholders Data not available</b>
                  </p>
                )}

              {!crudOperations[1] && (
                <p className="text-center">
                  <b> You are not Authorized</b>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
const TableStyle = styled.div`
  .onHover:hover {
    background-color: #ecf3f8 !important;
  }
  .onHover-dark:hover {
    background-color: #313131 !important;
  }
`;
const ScrollIconStyle = styled.div`
  .left_Icon:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .right_Icon:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
const WrapperForResponsive = styled.div`
  @media (max-width: 576px) {
    .shareholders-title {
      font-size: 14px;
      margin-bottom: 5px;
    }

    .btn-sm {
      font-size: 12px;
      padding: 0.25rem 0.5rem;
    }
  }
`;
