import React, { Fragment, useState, useEffect, useRef } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
  addShareholder,
  updateShareholder,
} from "../../../store/services/shareholder.service";
import { ToastContainer, toast } from "react-toastify";
import ToggleButton from "react-toggle-button";
import { getShares } from "../../../store/services/shareholder.service";
import {
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  ModalFooter,
} from "reactstrap";
import {
  getvalidDateYMD,
  IsJsonString,
  numberWithCommas,
} from "../../../utilities/utilityFunctions";
import JointHoldersItem from "./jointHolderItem";
import { darkStyle, errorStyles } from "../../defaultStyles";
import LoadableButton from "../../common/loadables";
import InputMask from "react-input-mask";
import NumberFormat from "react-number-format";
import Select from "react-select";

import {
  company_setter,
  folio_setter,
  investor_setter,
  symbol_setter,
} from "../../../services/dropdown.service";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  addShareHolderSchema,
  updateShareholderSchema,
} from "../../../store/validations/shareholderValidation";
import styled from "styled-components";
import { FaChevronRight } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa";
import moment from "moment";

export default function AddShareholders() {
  // holder
  const holder =
    JSON.parse(sessionStorage.getItem("selectedShareholder")) || "";

  const company_code = sessionStorage.getItem("company_code") || "";

  // joint holders
  const joint_holders = IsJsonString(holder.joint_holders)
    ? JSON.parse(holder.joint_holders)
    : [];
  // Selector STARTS
  // const companies_data = useSelector((data) => data.Companies.companies_data);
  // Selector ENDS
  // States
  const [loading, setLoading] = useState(false);
  //options
  const [symbol_options, setSymbol_options] = useState([]);
  const [company_options, setCompany_options] = useState([]);
  const [investor_options, setInvestor_options] = useState([]);
  const [folio_options, setFolio_options] = useState([]);
  // Toggle States
  const [filer, setFiler] = useState(holder.filer === "Y");
  const [zakat_status, setZakat_status] = useState(holder.zakat_status === "Y");
  const [roshan_account, setRoshan_account] = useState(
    holder.roshan_account === "Y"
  );
  // IMages
  const [cnic_copy, setCnic_copy] = useState(
    (holder?.cnic_copy === "_" ? "" : holder?.cnic_copy) || ""
  );
  const [nominee_cnic_copy, setNominee_cnic_copy] = useState(
    (holder?.nominee_cnic_copy === "_" ? "" : holder?.nominee_cnic_copy) || ""
  );
  const [zakat_declaration, setZakat_declaration] = useState(
    (holder?.zakat_declaration === "_" ? "" : holder?.zakat_declaration) || ""
  );
  const [signature_specimen, setSignature_specimen] = useState(
    (holder?.signature_specimen === "_" ? "" : holder?.signature_specimen) || ""
  );
  const [picture, setPicture] = useState(
    (holder?.picture === "_" ? "" : holder?.picture) || ""
  );
  const [viewModalPicture, setViewModalPicture] = useState(false);
  const [imagePath, setImagePath] = useState("");
  const [startcalculation, setStartcalculation] = useState(false);
  const [jointHolderObjects, setJointHolderObjects] = useState([]);
  // Validation Decalration
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm({
    defaultValues: updateShareholderSchema(holder).cast(),
    resolver: yupResolver(updateShareholderSchema(holder), {
      stripUnknown: true,
      abortEarly: false,
    }),
  });
  useEffect(async () => {
    try {
      setCompany_options(await company_setter());
      setInvestor_options(await investor_setter());
    } catch (err) {
      toast.error(`${err.response.data.message}`);
    }
  }, []);
  const startCalculation = (holders) => {
    const newArray = jointHolderObjects;
    newArray.push(holders);
    setJointHolderObjects(newArray);
  };

  const handleUpdateShareholder = async (data) => { };

  const handleViewImage = (pathVal) => {
    setImagePath(pathVal);
    setViewModalPicture(true);
  };

  const borderRadiusStyle = { borderRadius: 2 };
  const [screen, setScreen] = useState();

  useEffect(() => {
    const handleResize = () => {
      setScreen(window?.outerWidth);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const tableWrapperRef = React.useRef(null);
  const [isScrollable, setIsScrollable] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (tableWrapperRef.current) {
        const tableWidth = tableWrapperRef.current.scrollWidth;
        const wrapperWidth = tableWrapperRef.current.clientWidth;
        setIsScrollable(tableWidth > wrapperWidth);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleScrollLeft = (tableRef) => {
    if (tableRef.current) {
      tableRef.current.scrollTo({
        left: tableRef.current.scrollLeft - 200,
        behavior: "smooth",
      });
    }
  };

  const handleScrollRight = (tableRef) => {
    if (tableRef.current) {
      tableRef.current.scrollTo({
        left: tableRef.current.scrollLeft + 200,
        behavior: "smooth",
      });
    }
  };


  return (
    <div>
      <Fragment>
        <Modal
          isOpen={viewModalPicture}
          show={viewModalPicture.toString()}
          size="lg"
        >
          <ModalHeader
            toggle={() => {
              setViewModalPicture(false);
              setImagePath("");
            }}
          >
            View
          </ModalHeader>
          <ModalBody>
            {/* <img
              width="750"
              src={imagePath}
              alt="image_of_shareholder"
            /> */}
            <img
              className="img-fluid" // Add the img-fluid class
              style={{ maxWidth: "100%" }} // Add the max-width style
              width="750"
              src={imagePath}
              alt="image_of_shareholder"
            />
          </ModalBody>
        </Modal>
        <form onSubmit={handleSubmit(handleUpdateShareholder)}>
          <div className="row">
            <div className="col-sm-12 col-md-4 col-lg-4 ">
              <div className="card ">
                <div className="card-header b-t-primary">
                  <h5>Shareholder Details</h5>
                </div>
                <div className="card-body">
                  <div className="form-group my-2">
                    <label htmlFor="folio_no">Folio No</label>
                    <input
                      className="form-control"
                      type="text"
                      name="folio_no"
                      id="folio_no"
                      value={holder?.folio_number.replace(
                        `${company_code}-`,
                        ""
                      )}
                      readOnly
                    />
                  </div>
                  {/* <div className="form-group my-2">
                    <label htmlFor="company_code">Company</label>
                    <Controller
                      name="company_code"
                      render={({ field }) => (
                        <Select
                          {...field}
                          isLoading={company_options.length === 0}
                          options={company_options}
                          defaultValue={holder.company_code}
                          id="company_code"
                          styles={errors.company_code && errorStyles}
                          isDisabled={true}
                        />
                      )}
                      control={control}
                    />

                    <small className="text-danger">
                      {errors.company_code?.message}
                    </small>
                  </div> */}
                  {/* <div className="form-group my-2">
                    <label htmlFor="company_code">Company</label>
                    <input
                      className="form-control"
                      type="text"
                      name="company_code"
                      id="company_code"
                      value={holder.company_code.label}
                      readOnly
                    />
                  </div> */}
                  <div className="form-group my-2">
                    <label htmlFor="shareholder_name">Shareholder Name</label>
                    <input
                      name="shareholder_name"
                      className="form-control"
                      type="text"
                      // value={watch("shareholder_name")?.label}
                      value={holder.shareholder_name}
                      readOnly
                    />
                  </div>
                  <div className="form-group my-2">
                    <label htmlFor="shareholder_id">Shareholder ID</label>
                    <input
                      name="shareholder_id"
                      className="form-control"
                      type="text"
                      // value={holder?.shareholder_id?.label || ""}
                      value={holder?.shareholder_id || ""}
                      readOnly
                    />

                    <small className="text-danger">
                      {errors.shareholder_id?.message}
                    </small>
                  </div>
                  <div className="form-group my-2">
                    <label htmlFor="shareholder_mobile">Mobile</label>
                    <Controller
                      name="shareholder_mobile"
                      render={({ field }) => (
                        <InputMask
                          {...field}
                          className={`form-control ${errors.shareholder_mobile && "border border-danger"
                            }`}
                          mask="+\92-999-9999999"
                          readOnly
                        ></InputMask>
                      )}
                      control={control}
                    />

                    <small className="text-danger">
                      {errors.shareholder_mobile?.message}
                    </small>
                  </div>
                  <div className="form-group my-2">
                    <label htmlFor="shareholder_email">Email</label>
                    <input
                      name="shareholder_email"
                      className={`form-control ${errors.shareholder_email && "border border-danger"
                        }`}
                      type="text"
                      {...register("shareholder_email")}
                      readOnly
                    />
                    <small className="text-danger">
                      {errors.shareholder_email?.message}
                    </small>
                  </div>
                  <div className="form-group my-2">
                    <label htmlFor="shareholder_phone">Phone</label>
                    <Controller
                      name="shareholder_phone"
                      render={({ field }) => (
                        <InputMask
                          {...field}
                          className={`form-control ${errors.shareholder_phone && "border border-danger"
                            }`}
                          mask="+\92-99-9999999"
                          readOnly
                        ></InputMask>
                      )}
                      control={control}
                    />

                    <small className="text-danger">
                      {errors.shareholder_phone?.message}
                    </small>
                  </div>
                  <div className="form-group my-2">
                    <label htmlFor="resident_status">Resident Status</label>
                    <input
                      name="resident_status"
                      className={`form-control ${errors.resident_status && "border border-danger"
                        }`}
                      type="text"
                      {...register("resident_status")}
                      readOnly
                    />
                    <small className="text-danger">
                      {errors.resident_status?.message}
                    </small>
                  </div>
                  <div className="form-group my-2">
                    <label htmlFor="poc_detail">POC Detail</label>
                    <input
                      name="poc_detail"
                      className={`form-control ${errors.poc_detail && "border border-danger"
                        }`}
                      type="text"
                      {...register("poc_detail")}
                      readOnly
                    />
                    <small className="text-danger">
                      {errors.poc_detail?.message}
                    </small>
                  </div>
                  <div className="form-group my-2">
                    <label htmlFor="street_address">Street Address</label>
                    <textarea
                      name="street_address"
                      className={`form-control ${errors.street_address && "border border-danger"
                        }`}
                      type="text"
                      id="street_address"
                      {...register("street_address")}
                      readOnly
                    />
                    <small className="text-danger">
                      {errors.street_address?.message}
                    </small>
                  </div>
                  <div className="form-group my-2">
                    <label htmlFor="city">City</label>
                    <input
                      name="city"
                      className={`form-control ${errors.city && "border border-danger"
                        }`}
                      type="text"
                      {...register("city")}
                      readOnly
                    />
                    <small className="text-danger">
                      {errors.city?.message}
                    </small>
                  </div>
                  <div className="form-group my-2">
                    <label htmlFor="country">Country</label>
                    <input
                      name="country"
                      className={`form-control ${errors.country && "border border-danger"
                        }`}
                      type="text"
                      {...register("country")}
                      readOnly
                    />
                    <small className="text-danger">
                      {errors.country?.message}
                    </small>
                  </div>
                  <div className="form-group my-2">
                    <label htmlFor="nationality">Nationality</label>
                    <input
                      name="nationality"
                      className={`form-control ${errors.nationality && "border border-danger"
                        }`}
                      type="text"
                      {...register("nationality")}
                      readOnly
                    />
                    <small className="text-danger">
                      {errors.nationality?.message}
                    </small>
                  </div>
                </div>
              </div>

              <div className="card ">
                <div className="card-header b-t-success">
                  <h5>CDC Account Details</h5>
                </div>
                <div className="card-body">
                  <div className="form-group my-2">
                    <label htmlFor="cdc_account_no">CDC Account Number</label>
                    <input
                      className={`form-control ${errors.cdc_account_no && "border border-danger"
                        }`}
                      name="cdc_account_no"
                      type="text"
                      {...register("cdc_account_no")}
                      readOnly
                    />
                    <small className="text-danger">
                      {errors.cdc_account_no?.message}
                    </small>
                  </div>
                  <div className="form-group my-2">
                    <label htmlFor="cdc_participant_id">
                      CDC Participant ID
                    </label>
                    <input
                      className={`form-control ${errors.cdc_participant_id && "border border-danger"
                        }`}
                      name="cdc_participant_id"
                      type="text"
                      placeholder=""
                      {...register("cdc_participant_id")}
                      readOnly
                    />
                    <small className="text-danger">
                      {errors.cdc_participant_id?.message}
                    </small>
                  </div>
                  <div className="form-group my-2">
                    <label htmlFor="cdc_account_type">CDC Account Type</label>
                    <input
                      className={`form-control ${errors.cdc_account_type && "border border-danger"
                        }`}
                      name="cdc_account_type"
                      type="text"
                      placeholder=""
                      {...register("cdc_account_type")}
                      readOnly
                    />
                    <small className="text-danger">
                      {errors.cdc_account_type?.message}
                    </small>
                  </div>
                  <div className="form-group my-2">
                    <label htmlFor="cdc_key">CDC Key</label>
                    <input
                      className={`form-control ${errors.cdc_key && "border border-danger"
                        }`}
                      name="cdc_key"
                      type="text"
                      placeholder=""
                      {...register("cdc_key")}
                      readOnly
                    />
                    <small className="text-danger">
                      {errors.cdc_key?.message}
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-xl-4">
              <div className="card ">
                <div className="card-header b-t-primary">
                  <h5>Passport Details</h5>
                </div>
                <div className="card-body">
                  <div className="form-group my-2">
                    <label htmlFor="passport_no">Passport</label>
                    <input
                      name="passport_no"
                      className={`form-control ${errors.passport_no && "border border-danger"
                        }`}
                      type="text"
                      placeholder=""
                      {...register("passport_no")}
                      readOnly
                    />
                    <small className="text-danger">
                      {errors.passport_no?.message}
                    </small>
                  </div>
                  <div className="form-group my-2">
                    <label htmlFor="passport_expiry">Passport Expiry</label>
                    <input
                      name="passport_expiry"
                      className={`form-control ${errors.passport_expiry && "border border-danger"
                        }`}
                      value={watch("passport_expiry") !== "" && watch("passport_expiry") !== undefined ? moment(watch("passport_expiry"))?.format("DD-MMM-YYYY") : ""}
                      type="text"
                      {...register("passport_expiry")}
                      defaultValue={getvalidDateYMD(new Date())}
                      readOnly
                    />
                    <small className="text-danger">
                      {errors.passport_expiry?.message}
                    </small>
                  </div>
                  <div className="form-group my-2">
                    <label htmlFor="passport_country">Passport Country</label>
                    <input
                      name="passport_country"
                      className={`form-control ${errors.passport_country && "border border-danger"
                        }`}
                      type="text"
                      placeholder=""
                      {...register("passport_country")}
                      readOnly
                    />
                    <small className="text-danger">
                      {errors.passport_country?.message}
                    </small>
                  </div>
                </div>
              </div>
              <div className="card ">
                <div className="card-header b-t-success">
                  <h5>Shares Details</h5>
                </div>
                <div className="card-body">
                  <div className="form-group my-2">
                    <label>Shareholder Percent</label>
                    <div className="input-group mb-3">
                      <Controller
                        name="shareholder_percent"
                        render={({ field }) => (
                          <NumberFormat
                            {...field}
                            className={`form-control text-right ${errors.shareholder_percent &&
                              "border border-danger"
                              }`}
                            id="shareholder_percent"
                            allowNegative={false}
                            placeholder=""
                            readOnly
                          />
                        )}
                        control={control}
                        readOnly
                      />
                      <div className="input-group-append">
                        <span className="input-group-text" id="basic-addon2">
                          %
                        </span>
                      </div>
                    </div>
                    <small className="text-danger">
                      {errors.shareholder_percent?.message}
                    </small>
                  </div>
                  <div className="form-group my-2">
                    <label>Electronic Shares</label>
                    <Controller
                      name="electronic_shares"
                      render={({ field }) => (
                        <NumberFormat
                          {...field}
                          className={`form-control text-right ${errors.electronic_shares && "border-danger"
                            }`}
                          id="electronic_shares"
                          allowNegative={false}
                          thousandSeparator={true}
                          placeholder=""
                          readOnly
                        />
                      )}
                      control={control}
                      readOnly
                    />
                    <small className="text-danger">
                      {errors.electronic_shares?.message}
                    </small>
                  </div>
                  <div className="form-group my-2">
                    <label>Physical Shares</label>
                    <Controller
                      name="physical_shares"
                      render={({ field }) => (
                        <NumberFormat
                          {...field}
                          className={`form-control text-right ${errors.physical_shares && "border-danger"
                            }`}
                          id="physical_shares"
                          allowNegative={false}
                          thousandSeparator={true}
                          placeholder=""
                          readOnly
                        />
                      )}
                      control={control}
                      readOnly
                    />
                    <small className="text-danger">
                      {errors.physical_shares?.message}
                    </small>
                  </div>
                  <div className="form-group my-2">
                    <label>Right Shares</label>
                    <Controller
                      name="right_shares"
                      render={({ field }) => (
                        <NumberFormat
                          {...field}
                          className={`form-control text-right ${errors.right_shares && "border-danger"
                            }`}
                          id="right_shares"
                          allowNegative={false}
                          thousandSeparator={true}
                          placeholder=""
                          readOnly
                        />
                      )}
                      control={control}
                    />
                    <small className="text-danger">
                      {errors.right_shares?.message}
                    </small>
                  </div>
                  <div className="form-group my-2">
                    <label>Blocked Shares</label>
                    <Controller
                      name="blocked_shares"
                      render={({ field }) => (
                        <NumberFormat
                          {...field}
                          className={`form-control text-right ${errors.blocked_shares && "border-danger"
                            }`}
                          id="blocked_shares"
                          allowNegative={false}
                          thousandSeparator={true}
                          placeholder=""
                          readOnly
                        />
                      )}
                      control={control}
                      readOnly
                    />
                    <small className="text-danger">
                      {errors.blocked_shares?.message}
                    </small>
                  </div>
                  <div className="form-group my-2">
                    <label>Freeze Shares</label>
                    <Controller
                      name="freeze_shares"
                      render={({ field }) => (
                        <NumberFormat
                          {...field}
                          className={`form-control text-right ${errors.freeze_shares && "border-danger"
                            }`}
                          id="freeze_shares"
                          allowNegative={false}
                          thousandSeparator={true}
                          placeholder=""
                          readOnly
                        />
                      )}
                      control={control}
                      readOnly
                    />
                    <small className="text-danger">
                      {errors.freeze_shares?.message}
                    </small>
                  </div>
                  <div className="form-group my-2">
                    <label>Pledged Shares</label>
                    <Controller
                      name="pledged_shares"
                      render={({ field }) => (
                        <NumberFormat
                          {...field}
                          className={`form-control text-right ${errors.pledged_shares && "border-danger"
                            }`}
                          id="pledged_shares"
                          allowNegative={false}
                          thousandSeparator={true}
                          placeholder=""
                          readOnly
                        />
                      )}
                      control={control}
                      readOnly
                    />
                    <small className="text-danger">
                      {errors.pledged_shares?.message}
                    </small>
                  </div>
                  <div className="form-group my-2">
                    <label>Pending In</label>
                    <Controller
                      name="pending_in"
                      render={({ field }) => (
                        <NumberFormat
                          {...field}
                          className={`form-control text-right ${errors.pending_in && "border-danger"
                            }`}
                          id="pending_in"
                          allowNegative={false}
                          thousandSeparator={true}
                          placeholder=""
                          readOnly
                        />
                      )}
                      control={control}
                      readOnly
                    />
                    <small className="text-danger">
                      {errors.pending_in?.message}
                    </small>
                  </div>
                  <div className="form-group my-2">
                    <label>Pending Out</label>
                    <Controller
                      name="pending_out"
                      render={({ field }) => (
                        <NumberFormat
                          {...field}
                          className={`form-control text-right ${errors.pending_out && "border-danger"
                            }`}
                          id="pending_out"
                          allowNegative={false}
                          thousandSeparator={true}
                          placeholder=""
                          readOnly
                        />
                      )}
                      control={control}
                      readOnly
                    />
                    <small className="text-danger">
                      {errors.pending_out?.message}
                    </small>
                  </div>
                  <div className="form-group my-2">
                    <label>Available Shares</label>
                    <Controller
                      name="available_shares"
                      render={({ field }) => (
                        <NumberFormat
                          {...field}
                          className={`form-control text-right ${errors.available_shares && "border-danger"
                            }`}
                          id="available_shares"
                          allowNegative={false}
                          thousandSeparator={true}
                          placeholder=""
                          readOnly
                        />
                      )}
                      control={control}
                      readOnly
                    />
                    <small className="text-danger">
                      {errors.available_shares?.message}
                    </small>
                  </div>
                  <div className="form-group my-2">
                    <label>Total Holding</label>
                    <Controller
                      name="total_holding"
                      render={({ field }) => (
                        <NumberFormat
                          {...field}
                          className={`form-control text-right ${errors.total_holding && "border-danger"
                            }`}
                          id="total_holding"
                          allowNegative={false}
                          thousandSeparator={true}
                          placeholder=""
                          readOnly
                        />
                      )}
                      control={control}
                      readOnly
                    />
                    <small className="text-danger">
                      {errors.total_holding?.message}
                    </small>
                  </div>
                  <div className="form-group my-2">
                    <label>No of Joint Holders</label>
                    <Controller
                      name="no_joint_holders"
                      render={({ field }) => (
                        <NumberFormat
                          {...field}
                          className={`form-control ${errors.no_joint_holders && "border-danger"
                            }`}
                          id="no_joint_holders"
                          allowNegative={false}
                          placeholder=""
                          readOnly
                        />
                      )}
                      control={control}
                      readOnly
                    />
                    <small className="text-danger">
                      {errors.no_joint_holders?.message}
                    </small>
                  </div>
                </div>
              </div>
              <div className="card ">
                <div className="card-header b-t-success">
                  <h5>Nominee Details</h5>
                </div>
                <div className="card-body">
                  <div className="form-group my-2">
                    <label htmlFor="nominee_name">Nominee Name</label>
                    <input
                      className={`form-control ${errors.nominee_name && "border border-danger"
                        }`}
                      name="nominee_name"
                      type="text"
                      placeholder=""
                      {...register("nominee_name")}
                      readOnly
                    />
                    <small className="text-danger">
                      {errors.nominee_name?.message}
                    </small>
                  </div>
                  <div className="form-group my-2">
                    <label htmlFor="nominee_cnic">Nominee CNIC</label>
                    <Controller
                      name="nominee_cnic"
                      render={({ field }) => (
                        <InputMask
                          {...field}
                          className={`form-control ${errors.nominee_cnic && "border border-danger"
                            }`}
                          placeholder=""
                          mask="99999-9999999-9"
                          readOnly
                        ></InputMask>
                      )}
                      control={control}
                    />

                    <small className="text-danger">
                      {errors.nominee_cnic?.message}
                    </small>
                  </div>
                  <div className="form-group my-2">
                    <label htmlFor="nominee_relation">Nominee Relation</label>
                    <input
                      className={`form-control ${errors.nominee_relation && "border border-danger"
                        }`}
                      name="nominee_relation"
                      type="text"
                      placeholder=""
                      {...register("nominee_relation")}
                      readOnly
                    />
                    <small className="text-danger">
                      {errors.nominee_relation?.message}
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-xl-4">
              <div className="card ">
                <div className="card-header b-t-success">
                  <h5>Bank Details</h5>
                </div>
                <div className="card-body">
                  <div className="form-group my-2">
                    <label htmlFor="account_title">Account Title</label>
                    <input
                      className={`form-control ${errors.account_title && "border border-danger"
                        }`}
                      name="account_title"
                      type="text"
                      placeholder=""
                      {...register("account_title")}
                      readOnly
                    />
                    <small className="text-danger">
                      {errors.account_title?.message}
                    </small>
                  </div>
                  <div className="form-group my-2">
                    <label htmlFor="account_no">Account No.</label>
                    <input
                      name="account_no"
                      className={`form-control ${errors.account_no && "border border-danger"
                        }`}
                      type="text"
                      placeholder=""
                      {...register("account_no")}
                      readOnly
                    />
                    <small className="text-danger">
                      {errors.account_no?.message}
                    </small>
                  </div>
                  <div className="form-group my-2">
                    <label htmlFor="bank_name">Bank Name</label>
                    <input
                      className={`form-control ${errors.bank_name && "border border-danger"
                        }`}
                      name="bank_name"
                      type="text"
                      placeholder=""
                      {...register("bank_name")}
                      readOnly
                    />
                    <small className="text-danger">
                      {errors.bank_name?.message}
                    </small>
                  </div>
                  <div className="form-group my-2">
                    <label htmlFor="baranch_address">Branch Address</label>
                    <textarea
                      className={`form-control ${errors.baranch_address && "border border-danger"
                        }`}
                      type="text"
                      name="baranch_address"
                      id="baranch_address"
                      placeholder=""
                      {...register("baranch_address")}
                      readOnly
                    />
                    <small className="text-danger">
                      {errors.baranch_address?.message}
                    </small>
                  </div>
                  <div className="form-group my-2">
                    <label htmlFor="baranch_city">Branch City</label>
                    <input
                      name="baranch_city"
                      className={`form-control ${errors.baranch_city && "border border-danger"
                        }`}
                      type="text"
                      placeholder=""
                      {...register("baranch_city")}
                      readOnly
                    />
                    <small className="text-danger">
                      {errors.baranch_city?.message}
                    </small>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-lg-4 col-md-4">
                      <div className="form-group">
                        <br />
                        <label>Filer </label>
                        <br />
                        <ToggleButton
                          value={filer}
                          thumbStyle={borderRadiusStyle}
                          trackStyle={borderRadiusStyle}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-4 col-md-4">
                      <div className="form-group">
                        <label>Zakat Exempted </label>
                        <ToggleButton
                          value={zakat_status}
                          thumbStyle={borderRadiusStyle}
                          trackStyle={borderRadiusStyle}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-4 col-md-4">
                      <div className="form-group">
                        <label>Roshan Account </label>
                        <ToggleButton
                          value={roshan_account}
                          thumbStyle={borderRadiusStyle}
                          trackStyle={borderRadiusStyle}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header b-t-primary">
                  <h5>Attachments</h5>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="picture">Picture</label>
                    {/* <input
                      className={`form-control ${
                        errors.picture && "border border-danger"
                      }`}
                      name="picture"
                      type="file"
                      {...register("picture")}
                      onChange={(e) => {
                        if (e.target.files && e.target.files[0]) {
                          let img = e.target.files[0];
                          const reader = new FileReader();
                          reader.readAsDataURL(img);
                          reader.onload = function () {
                            setPicture(reader.result);
                          };
                        }
                      }}
                      readOnly
                    /> */}
                    <small className="text-danger d-block">
                      {errors.picture?.message}
                    </small>
                    {picture && (
                      <img
                        width="200"
                        onClick={() => handleViewImage(picture)}
                        src={picture}
                        alt="image_of_shareholder"
                      />
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="signature_specimen">
                      Signature Specimen
                    </label>
                    {/* <input
                      className={`form-control ${
                        errors.signature_specimen && "border border-danger"
                      }`}
                      name="signature_specimen"
                      type="file"
                      {...register("signature_specimen")}
                      onChange={(e) => {
                        if (e.target.files && e.target.files[0]) {
                          let img = e.target.files[0];
                          const reader = new FileReader();
                          reader.readAsDataURL(img);
                          reader.onload = function () {
                            setSignature_specimen(reader.result);
                          };
                        }
                      }}
                      readOnly
                    /> */}
                    <small className="text-danger d-block">
                      {errors.signature_specimen?.message}
                    </small>
                    {signature_specimen && (
                      <img
                        width="200"
                        src={signature_specimen}
                        onClick={() => handleViewImage(signature_specimen)}
                        alt="signature_specimen"
                      />
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="cnic_copy">CNIC Copy</label>
                    {/* <input
                      className={`form-control ${
                        errors.cnic_copy && "border border-danger"
                      }`}
                      name="cnic_copy"
                      type="file"
                      {...register("cnic_copy")}
                      onChange={(e) => {
                        if (e.target.files && e.target.files[0]) {
                          let img = e.target.files[0];
                          const reader = new FileReader();
                          reader.readAsDataURL(img);
                          reader.onload = function () {
                            setCnic_copy(reader.result);
                          };
                        }
                      }}
                      readOnly
                    /> */}
                    <small className="text-danger d-block">
                      {errors.cnic_copy?.message}
                    </small>
                    {cnic_copy && (
                      <img
                        width="200"
                        src={cnic_copy}
                        onClick={() => handleViewImage(cnic_copy)}
                        alt="cnic_copy"
                      />
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="nominee_cnic_copy">Nominee CNIC Copy</label>
                    {/* <input
                      className={`form-control ${
                        errors.nominee_cnic_copy && "border border-danger"
                      }`}
                      name="nominee_cnic_copy"
                      type="file"
                      {...register("nominee_cnic_copy")}
                      onChange={(e) => {
                        if (e.target.files && e.target.files[0]) {
                          let img = e.target.files[0];
                          const reader = new FileReader();
                          reader.readAsDataURL(img);
                          reader.onload = function () {
                            setNominee_cnic_copy(reader.result);
                          };
                        }
                      }}
                      readOnly
                    /> */}
                    <small className="text-danger d-block">
                      {errors.nominee_cnic_copy?.message}
                    </small>
                    {nominee_cnic_copy && (
                      <img
                        width="200"
                        src={nominee_cnic_copy}
                        onClick={() => handleViewImage(nominee_cnic_copy)}
                        alt="nominee_cnic_copy"
                      />
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="zakat_declaration">Zakat Declaration</label>
                    {/* <input
                      className={`form-control ${
                        errors.zakat_declaration && "border border-danger"
                      }`}
                      name="zakat_declaration"
                      type="file"
                      {...register("zakat_declaration")}
                      onChange={(e) => {
                        if (e.target.files && e.target.files[0]) {
                          let img = e.target.files[0];
                          const reader = new FileReader();
                          reader.readAsDataURL(img);
                          reader.onload = function () {
                            setZakat_declaration(reader.result);
                          };
                        }
                      }}
                      readOnly
                    /> */}
                    <small className="text-danger d-block">
                      {errors.zakat_declaration?.message}
                    </small>
                    {zakat_declaration && (
                      <img
                        width="200"
                        src={zakat_declaration}
                        onClick={() => handleViewImage(zakat_declaration)}
                        alt="zakat_declaration"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="card w-100 mx-4">
              <div className="card-header b-t-success">
                <b>JOINT HOLDERS</b>
              </div>
              <TableStyle>
                <div
                  style={{
                    position: "relative"
                  }}
                  className="card-body">

                  {watch("no_joint_holders") && (
                    <>
                      {isScrollable && (
                        <ScrollIconStyle>
                          <FaChevronLeft
                            style={{
                              position: "absolute",
                              left: "1px",
                              bottom: "8px",
                              color: "grey",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleScrollLeft(tableWrapperRef);
                            }}
                            className="left_Icon"
                          />
                        </ScrollIconStyle>
                      )}
                      <div ref={tableWrapperRef} className="table-responsive px-3">
                        <table className="table table-striped ">
                          <thead>
                            <tr>
                              <th className="text-nowrap">S No.</th>
                              <th className="text-nowrap">Joint Holder Name</th>
                              <th className="text-nowrap">Joint Holder CNIC</th>
                              <th className="text-nowrap">
                                Joint Holder CNIC Expiry
                              </th>
                              <th className="text-nowrap">Joint Holder Percent</th>
                              <th className="text-nowrap">Filer</th>
                            </tr>
                          </thead>
                          <tbody>
                            {[
                              ...Array(
                                Math.abs(parseInt(watch("no_joint_holders")))
                              ),
                            ].length > 0 &&
                              [...Array(parseInt(watch("no_joint_holders")))].map(
                                (jholders, index) => (
                                  <JointHoldersItem
                                    key={index}
                                    num={index + 1}
                                    startCalculation={startCalculation}
                                    calculated={true}
                                    jh_name={joint_holders[index]?.jointHolderName}
                                    jh_cnic={joint_holders[index]?.jointHolderCNIC}
                                    jh_cnic_expiry={
                                      joint_holders[index]?.jointHolderCNICExp
                                    }
                                    jh_percent={
                                      joint_holders[index]?.jointHolderPercent
                                    }
                                    jh_filer={joint_holders[index]?.filer}
                                  />
                                )
                              )}
                          </tbody>
                        </table>
                      </div>
                      {isScrollable && (
                        <ScrollIconStyle>
                          <FaChevronRight
                            style={{
                              position: "absolute",
                              right: "1px",
                              bottom: "8px",
                              color: "grey",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleScrollRight(tableWrapperRef);
                            }}
                            className="right_Icon"
                          />
                        </ScrollIconStyle>
                      )}
                    </>
                  )}
                </div>
              </TableStyle>
            </div>
          </div>
        </form>
      </Fragment>
    </div>
  );
}
const TableStyle = styled.div`
.onHover:hover{
  background-color:#ecf3f8 !important
}

`
const ScrollIconStyle = styled.div`
.left_Icon:hover{
  background-color:rgba(0,0,0,0.1)
}
.right_Icon:hover{
  background-color:rgba(0,0,0,0.1)
}
`
