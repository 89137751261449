import React, { useState, useEffect } from 'react'
import { PDFExport } from "@progress/kendo-react-pdf";
import PageTemplate from "./page-template";
import {
    getCompanies,
} from "../../../store/services/company.service";
import styled from 'styled-components';
import { TiTick } from "react-icons/ti";
import { numberWithCommas } from 'utilities/utilityFunctions';
import "./commonForm.css"
export const AppendixAtoApp3 = ({ data, formTemplate = false }) => {
    const pdfExportComponent = React.useRef(null);
    const [CompanyData, setCompanyData] = useState(null)
    const [loading, setLoading] = useState(false);
    const [fontFamilyForStaticData, setFontFamilyForStaticData] = useState('Montserrat, sans-serif')
    const [fontSizeForStaticData, setFontSizeForStaticData] = useState('14px')
    const [fontfamilyforDynimicData, setFontFamilyForDynmicData] = useState('Segoe Print')
    const [fontColor, setFontColor] = useState('')
    const baseEmail = sessionStorage.getItem("email") || "";
    const [governance, setGovernance] = useState({})
    const getAllCompanies = async () => {
        setLoading(true)
        try {
            const response = await getCompanies(baseEmail);
            if (response.status === 200) {
                response.data.data = response.data.data.filter((item) => item?.active === 'Y')
                const parents = response.data.data;
                const company = parents?.find(ite => ite?.code == data?.company_code)
                let Allperson = company.governance && company.governance != "" ? JSON.parse(company.governance) : []
                let sec = Allperson.find(x => x.role == "Company Secretary" && x?.active?.toLowerCase() != 'n')
                let ceo = Allperson.find(x => x.role == "CEO" && x?.active?.toLowerCase() != 'n')


                if (sec) {
                    setGovernance(sec)
                } else if (ceo) {
                    setGovernance(ceo)
                }
                setCompanyData(company)
                setTimeout(() => {
                    setLoading(false)
                }, 1000);
            }
        } catch (error) {
            setLoading(false)
        }
    };
    useEffect(() => {
        getAllCompanies()
    }, [])
    return (
        <div>
            <div
                style={{ fontFamily: "sans-serif " }}
            >

                {formTemplate ?
                    <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Appendix-A to App-3`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                        <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Appendix-A to App-3
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                    THE COMPANIES ACT, 2017
                                    </HeadingWrapper>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                    THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{ fontSize: '15px', fontWeight: 'bold' }} className='d-flex justify-content-center'>
                                    [Regulations 97]
                                    </div>
                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                    Information to be provided by promoters, proposed directors/directors, proposed cheif executive officer/cheif executive officer of the Company
                                    </HeadingWrapper>
                                </div>
                            </div>


                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                        {/* point 1 */}
                                        <div className='row'>
                                            <div className='col-12 mb-4'>
                                                <span className='aaa3-list1-no'>1</span>
                                                <span className='aaa3-list1-heading'>Profile</span>
                                            </div>
                                            {/* section 1.1 */}
                                            <div className='col-5'>
                                                <div className='d-flex'>
                                                    <span className='aaa3-list2-no'>1.1</span>
                                                    <span className='aaa3-list2-heading'>Name in Full including former name:</span>
                                                </div>
                                            </div>
                                            <div className='col-7 p-0'>
                                                <div style={{minHeight:"30px"}} className=' p-1 border-all border-bottom-0 h-100'></div>
                                            </div>
                                            {/* section 1.2 */}
                                            <div className='col-5'>
                                                <div className='d-flex'>
                                                    <span className='aaa3-list2-no mt-2'>1.2</span>
                                                    <span className='aaa3-list2-heading mt-2'>Nationality</span>
                                                </div>
                                            </div>
                                            <div className='col-7 p-0'>
                                            <div style={{minHeight:"30px"}} className=' p-1 border-all border-bottom-0 h-100'></div>
                                            </div>
                                            {/* section 1.3 */}
                                            <div className='col-5'>
                                                <div className='d-flex'>
                                                    <span className='aaa3-list2-no'>1.3</span>
                                                    <span className='aaa3-list2-heading'>NIC No or Passport No (in case of foreign national)</span>
                                                </div>
                                            </div>
                                            <div className='col-7 p-0'>
                                                <div style={{minHeight:"30px"}} className=' p-1 border-all border-bottom-0 h-100'></div>
                                            </div>
                                            {/* section 1.5 */}
                                            <div className='col-5'>
                                                <div className='d-flex'>
                                                    <span className='aaa3-list2-no mt-2'>1.5</span>
                                                    <span className='aaa3-list2-heading mt-2'>Contact details:</span>
                                                </div>
                                            </div>
                                            <div className='col-7 p-0'>
                                            <div style={{minHeight:"30px"}} className=' p-1 border-all border-bottom-0 h-100'></div>
                                            </div>
                                            {/* section 1.5.1 */}
                                            <div className='col-5'>
                                                <div className='d-flex'>
                                                    <span className='aaa3-list3-no mt-2'>1.5.1</span>
                                                    <span className='aaa3-list3-heading mt-2'>Residential Address</span>
                                                </div>
                                            </div>
                                            <div className='col-7 p-0'>
                                            <div style={{minHeight:"30px"}} className=' p-1 border-all border-bottom-0 h-100'></div>
                                            </div>
                                            {/* section 1.5.2 */}
                                            <div className='col-5'>
                                                <div className='d-flex'>
                                                    <span className='aaa3-list3-no mt-2'>1.5.2</span>
                                                    <span className='aaa3-list3-heading mt-2'>Business Address</span>
                                                </div>
                                            </div>
                                            <div className='col-7 p-0'>
                                            <div style={{minHeight:"30px"}} className=' p-1 border-all border-bottom-0 h-100'></div>
                                            </div>
                                            {/* section 1.5.3 */}
                                            <div className='col-5'>
                                                <div className='d-flex'>
                                                    <span className='aaa3-list3-no mt-2'>1.5.3</span>
                                                    <span className='aaa3-list3-heading mt-2'>Telephone Number</span>
                                                </div>
                                            </div>
                                            <div className='col-7 p-0'>
                                            <div style={{minHeight:"30px"}} className=' p-1 border-all border-bottom-0 h-100'></div>
                                            </div>
                                            {/* section 1.5.4 */}
                                            <div className='col-5'>
                                                <div className='d-flex'>
                                                    <span className='aaa3-list3-no mt-2'>1.5.4</span>
                                                    <span className='aaa3-list3-heading mt-2'>Mobile Number</span>
                                                </div>
                                            </div>
                                            <div className='col-7 p-0'>
                                            <div style={{minHeight:"30px"}} className=' p-1 border-all border-bottom-0 h-100'></div>
                                            </div>
                                            {/* section 1.5.5 */}
                                            <div className='col-5'>
                                                <div className='d-flex'>
                                                    <span className='aaa3-list3-no mt-2'>1.5.5</span>
                                                    <span className='aaa3-list3-heading mt-2'>Fax Number</span>
                                                </div>
                                            </div>
                                            <div className='col-7 p-0'>
                                            <div style={{minHeight:"30px"}} className=' p-1 border-all border-bottom-0 h-100'></div>
                                            </div>
                                            {/* section 1.5.6 */}
                                            <div className='col-5'>
                                                <div className='d-flex'>
                                                    <span className='aaa3-list3-no mt-2'>1.5.6</span>
                                                    <span className='aaa3-list3-heading mt-2'>Email Address</span>
                                                </div>
                                            </div>
                                            <div className='col-7 p-0'>
                                            <div style={{minHeight:"30px"}} className=' p-1 border-all border-bottom-0 h-100'></div>
                                            </div>
                                            {/* section 1.6 */}
                                            <div className='col-5'>
                                                <div className='d-flex'>
                                                    <span className='aaa3-list2-no mt-2'>1.6</span>
                                                    <span className='aaa3-list2-heading mt-2'>Academic and Professional Qualifications</span>
                                                </div>
                                            </div>
                                            <div className='col-7 p-0'>
                                                <div style={{minHeight:"30px"}} className=' p-1 border-all border-bottom-0 h-100'></div>
                                            </div>
                                            {/* section 1.7 */}
                                            <div className='col-5'>
                                                <div className='d-flex'>
                                                    <span className='aaa3-list2-no'>1.7</span>
                                                    <span className='aaa3-list2-heading'>Status</span>
                                                </div>
                                            </div>
                                            <div className='col-7 border-all px-1'>
                                                <div class="aaa3-checkbox">
                                                    <label className='aaa3-checkbox-label' htmlFor="checkbox_aaa3_promoter">Promoter</label>
                                                    <div className='border-all aaa3-checkbox-input'  ></div>
                                                </div>
                                                <div class="aaa3-checkbox">
                                                    <label className='aaa3-checkbox-label mr-3' htmlFor="checkbox_aaa3_director">Director</label>
                                                    <div className='border-all aaa3-checkbox-input'  ></div>
                                                    <label className='aaa3-checkbox-label ml-3' htmlFor="checkbox_aaa3_ceo">Cheif Executive Officer</label>
                                                    <div className='border-all aaa3-checkbox-input'  ></div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* point 2 */}
                                        <div className='mb-4'>
                                            <span className='aaa3-list1-no'>2</span>
                                            <span className='aaa3-list1-heading'>Experience Detail:</span>
                                        </div>
                                        <div className='table-responsive'>
                                            <table className='table border border-dark'>
                                                <tbody>
                                                    <tr className=''>
                                                        <td className='vertical-align-top border border-dark text-center'>S#</td>
                                                        <td className='vertical-align-top border border-dark'>Name of organization</td>
                                                        <td className='vertical-align-top border border-dark'>Designation</td>
                                                        <td className='vertical-align-top border border-dark'>Work responsibilities related to objects of proposed company (mention reference of clause number of draft MoA)</td>
                                                        <td className='vertical-align-top border border-dark'>From (period latest to old)</td>
                                                        <td className='vertical-align-top border border-dark'>To</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='border border-dark p-0 td-h'>
                                                            <div className=' border-none text-center px-2' ></div>
                                                        </td>
                                                        <td className='border border-dark p-0 td-h'>
                                                            <div className=' border-none px-2' ></div>
                                                        </td>
                                                        <td className='border border-dark p-0 td-h'>
                                                            <div className=' border-none border-dark p-1' ></div>
                                                        </td>
                                                        <td className='border border-dark p-0 td-h'>
                                                            <div className=' border-none px-2' ></div>
                                                        </td>
                                                        <td className='border border-dark p-0 td-h'>
                                                            <div className=' border-none px-2' ></div>
                                                        </td>
                                                        <td className='border border-dark p-0 td-h'>
                                                            <div className=' border-none px-2' ></div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className='border border-dark p-0 td-h'>
                                                            <div className=' border-none text-center px-2' ></div>
                                                        </td>
                                                        <td className='border border-dark p-0 td-h'>
                                                            <div className=' border-none px-2' ></div>
                                                        </td>
                                                        <td className='border border-dark p-0 td-h'>
                                                            <div className=' border-none border-dark px-2' ></div>
                                                        </td>
                                                        <td className='border border-dark p-0 td-h'>
                                                            <div className=' border-none px-2' ></div>
                                                        </td>
                                                        <td className='border border-dark p-0 td-h'>
                                                            <div className=' border-none px-2' ></div>
                                                        </td>
                                                        <td className='border border-dark p-0 td-h'>
                                                            <div className=' border-none px-2' ></div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className='border border-dark p-0 td-h'>
                                                            <div className=' border-none text-center px-2' ></div>
                                                        </td>
                                                        <td className='border border-dark p-0 td-h'>
                                                            <div className=' border-none px-2' ></div>
                                                        </td>
                                                        <td className='border border-dark p-0 td-h'>
                                                            <div className=' border-none border-dark px-2' ></div>
                                                        </td>
                                                        <td className='border border-dark p-0 td-h'>
                                                            <div className=' border-none px-2' ></div>
                                                        </td>
                                                        <td className='border border-dark p-0 td-h'>
                                                            <div className=' border-none px-2' ></div>
                                                        </td>
                                                        <td className='border border-dark p-0 td-h'>
                                                            <div className=' border-none px-2' ></div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div className='mt-2'>
                                                Note: If needed, separate extra sheets can be used for each item
                                            </div>
                                        </div>











                                </div>
                            </div>
                        </div>
                    </PDFExport>
                    : <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Appendix-A to App-3`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                        <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Appendix-A to App-3
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                    THE COMPANIES ACT, 2017
                                    </HeadingWrapper>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                    THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{ fontSize: '15px', fontWeight: 'bold' }} className='d-flex justify-content-center'>
                                    [Regulations 97]
                                    </div>
                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                    Information to be provided by promoters, proposed directors/directors, proposed cheif executive officer/cheif executive officer of the Company
                                    </HeadingWrapper>
                                </div>
                            </div>


                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                        {/* point 1 */}
                                        <div className='row'>
                                            <div className='col-12 mb-4'>
                                                <span className='aaa3-list1-no'>1</span>
                                                <span className='aaa3-list1-heading'>Profile</span>
                                            </div>
                                            {/* section 1.1 */}
                                            <div className='col-5'>
                                                <div className='d-flex'>
                                                    <span className='aaa3-list2-no'>1.1</span>
                                                    <span className='aaa3-list2-heading'>Name in Full including former name:</span>
                                                </div>
                                            </div>
                                            <div className='col-7 p-0'>
                                                <div style={{minHeight:"30px"}} className=' p-1 border-all border-bottom-0 h-100'></div>
                                            </div>
                                            {/* section 1.2 */}
                                            <div className='col-5'>
                                                <div className='d-flex'>
                                                    <span className='aaa3-list2-no mt-2'>1.2</span>
                                                    <span className='aaa3-list2-heading mt-2'>Nationality</span>
                                                </div>
                                            </div>
                                            <div className='col-7 p-0'>
                                            <div style={{minHeight:"30px"}} className=' p-1 border-all border-bottom-0 h-100'></div>
                                            </div>
                                            {/* section 1.3 */}
                                            <div className='col-5'>
                                                <div className='d-flex'>
                                                    <span className='aaa3-list2-no'>1.3</span>
                                                    <span className='aaa3-list2-heading'>NIC No or Passport No (in case of foreign national)</span>
                                                </div>
                                            </div>
                                            <div className='col-7 p-0'>
                                                <div style={{minHeight:"30px"}} className=' p-1 border-all border-bottom-0 h-100'></div>
                                            </div>
                                            {/* section 1.5 */}
                                            <div className='col-5'>
                                                <div className='d-flex'>
                                                    <span className='aaa3-list2-no mt-2'>1.5</span>
                                                    <span className='aaa3-list2-heading mt-2'>Contact details:</span>
                                                </div>
                                            </div>
                                            <div className='col-7 p-0'>
                                            <div style={{minHeight:"30px"}} className=' p-1 border-all border-bottom-0 h-100'></div>
                                            </div>
                                            {/* section 1.5.1 */}
                                            <div className='col-5'>
                                                <div className='d-flex'>
                                                    <span className='aaa3-list3-no mt-2'>1.5.1</span>
                                                    <span className='aaa3-list3-heading mt-2'>Residential Address</span>
                                                </div>
                                            </div>
                                            <div className='col-7 p-0'>
                                            <div style={{minHeight:"30px"}} className=' p-1 border-all border-bottom-0 h-100'></div>
                                            </div>
                                            {/* section 1.5.2 */}
                                            <div className='col-5'>
                                                <div className='d-flex'>
                                                    <span className='aaa3-list3-no mt-2'>1.5.2</span>
                                                    <span className='aaa3-list3-heading mt-2'>Business Address</span>
                                                </div>
                                            </div>
                                            <div className='col-7 p-0'>
                                            <div style={{minHeight:"30px"}} className=' p-1 border-all border-bottom-0 h-100'></div>
                                            </div>
                                            {/* section 1.5.3 */}
                                            <div className='col-5'>
                                                <div className='d-flex'>
                                                    <span className='aaa3-list3-no mt-2'>1.5.3</span>
                                                    <span className='aaa3-list3-heading mt-2'>Telephone Number</span>
                                                </div>
                                            </div>
                                            <div className='col-7 p-0'>
                                            <div style={{minHeight:"30px"}} className=' p-1 border-all border-bottom-0 h-100'></div>
                                            </div>
                                            {/* section 1.5.4 */}
                                            <div className='col-5'>
                                                <div className='d-flex'>
                                                    <span className='aaa3-list3-no mt-2'>1.5.4</span>
                                                    <span className='aaa3-list3-heading mt-2'>Mobile Number</span>
                                                </div>
                                            </div>
                                            <div className='col-7 p-0'>
                                            <div style={{minHeight:"30px"}} className=' p-1 border-all border-bottom-0 h-100'></div>
                                            </div>
                                            {/* section 1.5.5 */}
                                            <div className='col-5'>
                                                <div className='d-flex'>
                                                    <span className='aaa3-list3-no mt-2'>1.5.5</span>
                                                    <span className='aaa3-list3-heading mt-2'>Fax Number</span>
                                                </div>
                                            </div>
                                            <div className='col-7 p-0'>
                                            <div style={{minHeight:"30px"}} className=' p-1 border-all border-bottom-0 h-100'></div>
                                            </div>
                                            {/* section 1.5.6 */}
                                            <div className='col-5'>
                                                <div className='d-flex'>
                                                    <span className='aaa3-list3-no mt-2'>1.5.6</span>
                                                    <span className='aaa3-list3-heading mt-2'>Email Address</span>
                                                </div>
                                            </div>
                                            <div className='col-7 p-0'>
                                            <div style={{minHeight:"30px"}} className=' p-1 border-all border-bottom-0 h-100'></div>
                                            </div>
                                            {/* section 1.6 */}
                                            <div className='col-5'>
                                                <div className='d-flex'>
                                                    <span className='aaa3-list2-no mt-2'>1.6</span>
                                                    <span className='aaa3-list2-heading mt-2'>Academic and Professional Qualifications</span>
                                                </div>
                                            </div>
                                            <div className='col-7 p-0'>
                                                <div style={{minHeight:"30px"}} className=' p-1 border-all border-bottom-0 h-100'></div>
                                            </div>
                                            {/* section 1.7 */}
                                            <div className='col-5'>
                                                <div className='d-flex'>
                                                    <span className='aaa3-list2-no'>1.7</span>
                                                    <span className='aaa3-list2-heading'>Status</span>
                                                </div>
                                            </div>
                                            <div className='col-7 border-all px-1'>
                                                <div class="aaa3-checkbox">
                                                    <label className='aaa3-checkbox-label' htmlFor="checkbox_aaa3_promoter">Promoter</label>
                                                    <div className='border-all aaa3-checkbox-input'  ></div>
                                                </div>
                                                <div class="aaa3-checkbox">
                                                    <label className='aaa3-checkbox-label mr-3' htmlFor="checkbox_aaa3_director">Director</label>
                                                    <div className='border-all aaa3-checkbox-input'  ></div>
                                                    <label className='aaa3-checkbox-label ml-3' htmlFor="checkbox_aaa3_ceo">Cheif Executive Officer</label>
                                                    <div className='border-all aaa3-checkbox-input'  ></div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* point 2 */}
                                        <div className='mb-4'>
                                            <span className='aaa3-list1-no'>2</span>
                                            <span className='aaa3-list1-heading'>Experience Detail:</span>
                                        </div>
                                        <div className='table-responsive'>
                                            <table className='table border border-dark'>
                                                <tbody>
                                                    <tr className=''>
                                                        <td className='vertical-align-top border border-dark text-center'>S#</td>
                                                        <td className='vertical-align-top border border-dark'>Name of organization</td>
                                                        <td className='vertical-align-top border border-dark'>Designation</td>
                                                        <td className='vertical-align-top border border-dark'>Work responsibilities related to objects of proposed company (mention reference of clause number of draft MoA)</td>
                                                        <td className='vertical-align-top border border-dark'>From (period latest to old)</td>
                                                        <td className='vertical-align-top border border-dark'>To</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='border border-dark p-0 td-h'>
                                                            <div className=' border-none text-center px-2' ></div>
                                                        </td>
                                                        <td className='border border-dark p-0 td-h'>
                                                            <div className=' border-none px-2' ></div>
                                                        </td>
                                                        <td className='border border-dark p-0 td-h'>
                                                            <div className=' border-none border-dark p-1' ></div>
                                                        </td>
                                                        <td className='border border-dark p-0 td-h'>
                                                            <div className=' border-none px-2' ></div>
                                                        </td>
                                                        <td className='border border-dark p-0 td-h'>
                                                            <div className=' border-none px-2' ></div>
                                                        </td>
                                                        <td className='border border-dark p-0 td-h'>
                                                            <div className=' border-none px-2' ></div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className='border border-dark p-0 td-h'>
                                                            <div className=' border-none text-center px-2' ></div>
                                                        </td>
                                                        <td className='border border-dark p-0 td-h'>
                                                            <div className=' border-none px-2' ></div>
                                                        </td>
                                                        <td className='border border-dark p-0 td-h'>
                                                            <div className=' border-none border-dark px-2' ></div>
                                                        </td>
                                                        <td className='border border-dark p-0 td-h'>
                                                            <div className=' border-none px-2' ></div>
                                                        </td>
                                                        <td className='border border-dark p-0 td-h'>
                                                            <div className=' border-none px-2' ></div>
                                                        </td>
                                                        <td className='border border-dark p-0 td-h'>
                                                            <div className=' border-none px-2' ></div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className='border border-dark p-0 td-h'>
                                                            <div className=' border-none text-center px-2' ></div>
                                                        </td>
                                                        <td className='border border-dark p-0 td-h'>
                                                            <div className=' border-none px-2' ></div>
                                                        </td>
                                                        <td className='border border-dark p-0 td-h'>
                                                            <div className=' border-none border-dark px-2' ></div>
                                                        </td>
                                                        <td className='border border-dark p-0 td-h'>
                                                            <div className=' border-none px-2' ></div>
                                                        </td>
                                                        <td className='border border-dark p-0 td-h'>
                                                            <div className=' border-none px-2' ></div>
                                                        </td>
                                                        <td className='border border-dark p-0 td-h'>
                                                            <div className=' border-none px-2' ></div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div className='mt-2'>
                                                Note: If needed, separate extra sheets can be used for each item
                                            </div>
                                        </div>











                                </div>
                            </div>
                        </div>
                    </PDFExport>}
            </div>
            <button disabled={loading} className='btn btn-danger' onClick={(e) => {
                // if(viewPdf)
                //   SetViewPDf(true)
                if (pdfExportComponent.current) {
                    pdfExportComponent.current.save();
                }
            }}>Download PDF</button>
        </div>
    )
}
const PartsWrapper = styled.div`
color: #121212;
text-decoration: underline;
justify-content: center;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const ScrollWrapper = styled.div`
max-height: 50px;
overflow-y: scroll;
overflow-x: hidden;
::-webkit-scrollbar{
    height: 5px;
    width: 3px;
  }
  
  ::-webkit-scrollbar-track{
    background: #F9F9FB;
  }
  ::-webkit-scrollbar-thumb{
    background: #4E515680;
    border-radius: 5px;
  
  }
`;
const HeadingWrapper = styled.div`
font-size: 17px;
font-weight: bold;
`;
const FormName = styled.div`
color: #121212;
text-decoration: underline;
justify-content: end;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const Wrapper = styled.div`
table.table-bordered{
    border: 1px solid #121212
     border-color: #121212; 
    // border:1px solid blue;
    // margin-top:20px;
  }
// table.table-bordered > thead > tr > th{
//     // border:1px solid blue;
//     border-color: #121212; 
// }
// table.table-bordered > tbody > tr > td{
//     // border:1px solid blue;
//     borderC-color: #121212; 
// }
 tr td {
    // border:1px solid blue;.
    border-color: #121212; 
}

`;
const WrapperDashed = styled.div`

  td {
    
    // border: 1px dashed #dddcdc;
}

`;