import React, { useState, useEffect } from 'react'
import { PDFExport } from "@progress/kendo-react-pdf";
import PageTemplate from "./page-template";
import {
    getCompanies,
} from "../../../store/services/company.service";
import styled from 'styled-components';
import { TiTick } from "react-icons/ti";
import { numberWithCommas } from 'utilities/utilityFunctions';
import "./commonForm.css"
export const StatutoryForm20 = ({ data, formTemplate = false }) => {
    const pdfExportComponent = React.useRef(null);
    const [CompanyData, setCompanyData] = useState(null)
    const [loading, setLoading] = useState(false);
    const [fontFamilyForStaticData, setFontFamilyForStaticData] = useState('Montserrat, sans-serif')
    const [fontSizeForStaticData, setFontSizeForStaticData] = useState('14px')
    const [fontfamilyforDynimicData, setFontFamilyForDynmicData] = useState('Segoe Print')
    const [fontColor, setFontColor] = useState('')
    const baseEmail = sessionStorage.getItem("email") || "";
    const [governance, setGovernance] = useState({})
    const getAllCompanies = async () => {
        setLoading(true)
        try {
            const response = await getCompanies(baseEmail);
            if (response.status === 200) {
                response.data.data = response.data.data.filter((item) => item?.active === 'Y')
                const parents = response.data.data;
                const company = parents?.find(ite => ite?.code == data?.company_code)
                let Allperson = company.governance && company.governance != "" ? JSON.parse(company.governance) : []
                let sec = Allperson.find(x => x.role == "Company Secretary" && x?.active?.toLowerCase() != 'n')
                let ceo = Allperson.find(x => x.role == "CEO" && x?.active?.toLowerCase() != 'n')


                if (sec) {
                    setGovernance(sec)
                } else if (ceo) {
                    setGovernance(ceo)
                }
                setCompanyData(company)
                setTimeout(() => {
                    setLoading(false)
                }, 1000);
            }
        } catch (error) {
            setLoading(false)
        }
    };
    useEffect(() => {
        getAllCompanies()
    }, [])
    return (
        <div>
            <div
                style={{ fontFamily: "sans-serif " }}
            >

                {formTemplate ?
                    <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Form 20`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                        <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Form-20
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{
                                        fontSize: '15px',
                                        // fontWeight: 'bold' 
                                    }} className='d-flex justify-content-center'>
                                        [Section 227(2)(f) and Regulation 30]
                                    </div>

                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        PATTERN OF SHAREHOLDING
                                    </HeadingWrapper>

                                </div>
                            </div>

                            <PartsWrapper className='mt-4 mb-3'>
                                PART-I
                            </PartsWrapper>

                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div className='row'>

                                        <div className='col-12'>
                                            <em>(Please complete in typescript or in bold block capitals.)</em>
                                        </div>
                                        {/* section 1.1 */}
                                        <div className='col-12 mb-3'>
                                            <div className='row'>
                                                <div className='col-5 d-flex '><span className='form-sr-no mt-2'>1.1 </span>
                                                    <span className='mt-2'>Name of the Company</span>
                                                </div>
                                                <div className='col-7'>
                                                    <div style={{ minHeight: "35px" }} className="company-name border-all ml-1" ></div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* part II */}
                                        <PartsWrapper className='mt-4 mb-3'>
                                            PART-II
                                        </PartsWrapper>
                                        {/* <div className='col-12 mb-1'>
                    <h6 className='text-center'><u>PART-II</u></h6>
                </div> */}
                                        {/* section 2.1 */}
                                        <div className='col-12 mb-2'>
                                            <div className='row'>
                                                <div className='col-8'>
                                                    <div className='d-flex '>
                                                        <span className='form-sr-no'>2.1 </span>
                                                        <span className='word-spacing-10'>Pattern of holding of the shares held by the shareholders as at</span>
                                                    </div>
                                                </div>
                                                <div className='col-4 d-flex'>
                                                    <span className='d-flex ml-4 mr-2'>
                                                        <div className="form-20-date-cell text-center border-all"></div>
                                                        <div className="form-20-date-cell text-center border-all border-left-0"></div>
                                                    </span>
                                                    <span className='d-flex mx-2'>
                                                        <div className="form-20-date-cell text-center border-all"></div>
                                                        <div className="form-20-date-cell text-center border-all border-left-0"></div>
                                                    </span>
                                                    <span className='d-flex mx-2'>
                                                        <div className="form-20-date-cell text-center border-all"></div>
                                                        <div className="form-20-date-cell text-center border-all border-left-0"></div>
                                                        <div className="form-20-date-cell text-center border-all border-left-0"></div>
                                                        <div className="form-20-date-cell text-center border-all border-left-0" ></div>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* section 2.2 */}
                                    <div className='row mb-2'>
                                        <div className='col-3 box border border-dark '>
                                            <div className='d-flex'>
                                                <span className='form-sr-no'>2.2</span>
                                                <span>No of</span>
                                            </div>
                                            <span className='ml-3 pl-2'>shareholders</span>
                                        </div>
                                        <div className='col-5 box border border-dark text-center vertical-align-top '>
                                            <u>Shareholdings</u>
                                        </div>
                                        <div className='col-3 border border-dark text-center vertical-align-top '>
                                            <u>Total shares held</u>
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <div className='col-3 box p-0'>
                                            <div className=' border-all py-0 h-100'></div>
                                        </div>
                                        <div className='col-5 d-flex align-items-center box border border-dark '>
                                            <span>shareholding from 1 to 100 shares</span>
                                        </div>
                                        <div className='col-3 p-0'>
                                            <div className=' border-all py-0 h-100'></div>
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <div className='col-3 box p-0'>
                                            <div className='border-all py-0 h-100'></div>
                                        </div>
                                        <div className='col-5 d-flex align-items-center box border border-dark '>
                                            <span>shareholding from 101 to 500 shares</span>
                                        </div>
                                        <div className='col-3 p-0'>
                                            <div className='border-all py-0 h-100'></div>
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <div className='col-3 box p-0'>
                                            <div className='border-all py-0 h-100' ></div>
                                        </div>
                                        <div className='col-5 d-flex align-items-center box border border-dark '>
                                            <span>shareholding from 501 to 1000 shares</span>
                                        </div>
                                        <div className='col-3 p-0'>
                                            <div className='border-all py-0 h-100' ></div>
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <div className='col-3 box p-0'>
                                            <div className='border-all py-0 h-100' ></div>
                                        </div>
                                        <div className='col-5 d-flex align-items-center box border border-dark '>
                                            <span>shareholding from 1001 to 5000 shares</span>
                                        </div>
                                        <div className='col-3 p-0'>
                                            <div className='border-all py-0 h-100' ></div>
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <div className='col-3 box p-0'>
                                            <div className='border-all py-0 h-100' ></div>
                                        </div>
                                        <div className='col-5 box d-flex align-items-center box border border-dark '>
                                            <span>shareholding from 5001 to 10000 shares</span>
                                        </div>
                                        <div className='col-3 p-0'>
                                            <div className='border-all py-0 h-100' ></div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-3 box p-0'>
                                            <div className='border-all py-0 input-h-20' ></div>
                                        </div>
                                        <div className='col-5 box p-0'>
                                            <div className='border-all py-0 input-h-20' ></div>
                                        </div>
                                        <div className='col-3 p-0'>
                                            <div className='border-all py-0 input-h-20' ></div>
                                        </div>
                                        <div className='col-12 text-center'>
                                            <i className=''>(Add appropriate slabs of shareholdings)</i>
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <div className='col-3 box p-0'>
                                            <div className='border-all py-0 input-h-20' ></div>
                                        </div>
                                        <div className='col-5 d-flex box p-0'>
                                            <span className=' position-absolute pl-2'>Total</span>
                                            <div style={{width:"100%"}} className='border-all pl-5 py-0 input-h-20' ></div>
                                        </div>
                                        <div className='col-3 p-0'>
                                            <div className='border-all py-0 input-h-20' ></div>
                                        </div>
                                    </div>
                                    {/* section 2.3 */}
                                    <div className='row mb-2'>
                                        <div className='col-5 box border border-dark '>
                                            <div className='d-flex'>
                                                <span className='form-sr-no border-right border-dark pr-2'>2.3</span>
                                                <span>Categories of shareholders</span>
                                            </div>
                                        </div>
                                        <div className='col-3 box border border-dark text-center '>
                                            <span>share held</span>
                                        </div>
                                        <div className='col-3 border border-dark text-center '>
                                            <span>Percentage</span>
                                        </div>
                                    </div>
                                    {/* section 2.3.1 */}
                                    <div className='row mb-2'>
                                        <div className='col-5 box  pl-5'>
                                            <div className='d-flex'>
                                                <span className='form-sr-no pl-2'>2.3.1</span>
                                                <span className='text-align-justify'>Directors, Chief Executive Officer, and their spouse and minor children.</span>
                                            </div>
                                        </div>
                                        <div className='col-3 box p-0'>
                                            <div className=' border-all p-1 h-100'></div>
                                        </div>
                                        <div className='col-3 p-0'>
                                            <div className=' border-all p-1 h-100'></div>
                                        </div>
                                    </div>
                                    {/* section 2.3.2 */}
                                    <div className='row mb-2'>
                                        <div className='col-5 box  pl-5'>
                                            <div className='d-flex'>
                                                <span className='form-sr-no pl-2'>2.3.2</span>
                                                <span className='text-align-justify'>Associated Companies, undertakings and related parties.</span>
                                            </div>
                                        </div>
                                        <div className='col-3 box p-0'>
                                            <div className=' border-all p-1 h-100'></div>
                                        </div>
                                        <div className='col-3 p-0'>
                                            <div className=' border-all p-1 h-100'></div>
                                        </div>
                                    </div>
                                    {/* section 2.3.3 */}
                                    <div className='row mb-2'>
                                        <div className='col-5 box  pl-5'>
                                            <div className='d-flex align-items-center'>
                                                <span className='form-sr-no pl-2'>2.3.3</span>
                                                <span>NIT and ICP</span>
                                            </div>
                                        </div>
                                        <div className='col-3 box p-0'>
                                            <div className=' border-all p-1 h-100' ></div>
                                        </div>
                                        <div className='col-3 p-0'>
                                            <div className=' border-all p-1 h-100' ></div>
                                        </div>
                                    </div>
                                    {/* section 2.3.4 */}
                                    <div className='row mb-2'>
                                        <div className='col-5 box  pl-5'>
                                            <div className='d-flex'>
                                                <span className='form-sr-no pl-2'>2.3.4</span>
                                                <span className='text-align-justify'>Banks Development Financial Institutions, Non Banking Financial Institutions.</span>
                                            </div>
                                        </div>
                                        <div className='col-3 box p-0'>
                                            <div className=' border-all p-1 h-100'></div>
                                        </div>
                                        <div className='col-3 p-0'>
                                            <div className=' border-all p-1 h-100'></div>
                                        </div>
                                    </div>
                                    {/* section 2.3.5 */}
                                    <div className='row mb-2'>
                                        <div className='col-5 box  pl-5'>
                                            <div className='d-flex align-items-center'>
                                                <span className='form-sr-no pl-2'>2.3.5</span>
                                                <span>Insurance Companies</span>
                                            </div>
                                        </div>
                                        <div className='col-3 box p-0'>
                                            <div className=' border-all p-1 h-100' ></div>
                                        </div>
                                        <div className='col-3 p-0'>
                                            <div className=' border-all p-1 h-100' ></div>
                                        </div>
                                    </div>
                                    {/* section 2.3.6 */}
                                    <div className='row mb-2'>
                                        <div className='col-5 box pl-5'>
                                            <div className='d-flex align-items-center mt-2'>
                                                <span className='form-sr-no  pl-2'>2.3.6</span>
                                                <span className='text-align-justify '>Modarabas and Mutual Funds</span>
                                            </div>
                                        </div>
                                        <div className='col-3 box p-0'>
                                            <div className=' border-all p-1 h-100' ></div>
                                        </div>
                                        <div className='col-3 p-0'>
                                            <div className=' border-all p-1 h-100' ></div>
                                        </div>
                                    </div>
                                    {/* section 2.3.7 */}
                                    <div className='row mb-2'>
                                        <div className='col-5 box  pl-5'>
                                            <div className='d-flex align-items-center mt-2'>
                                                <span className='form-sr-no pl-2'>2.3.7</span>
                                                <span className='text-align-justify'>Share holders holding 10%</span>
                                            </div>
                                        </div>
                                        <div className='col-3 box p-0'>
                                            <div className=' border-all p-1 h-100' ></div>
                                        </div>
                                        <div className='col-3 p-0'>
                                            <div className=' border-all p-1 h-100' ></div>
                                        </div>
                                    </div>
                                    {/* section 2.3.8 */}
                                    <div className='row mb-2'>
                                        <div className='col-12 box  pl-5'>
                                            <div className='d-flex align-items-center'>
                                                <span className='form-sr-no pl-2'>2.3.8</span>
                                                <span>Genral Public</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <div className='col-5 box  pl-5'>
                                            <div className='d-flex align-items-center pl-5 mt-2'>
                                                <span className='form-sr-no pl-5'>a.</span>
                                                <span className='text-align-justify'>Local</span>
                                            </div>
                                        </div>
                                        <div className='col-3 box p-0'>
                                            <div className=' border-all p-1 h-100' ></div>
                                        </div>
                                        <div className='col-3 p-0'>
                                            <div className=' border-all p-1 h-100' ></div>
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <div className='col-5 box  pl-5'>
                                            <div className='d-flex align-items-center pl-5 mt-2'>
                                                <span className='form-sr-no pl-5'>b.</span>
                                                <span className='text-align-justify'>Foreign</span>
                                            </div>
                                        </div>
                                        <div className='col-3 box p-0'>
                                        <div className=' border-all p-1 h-100' ></div>
                                        </div>
                                        <div className='col-3 p-0'>
                                        <div className=' border-all p-1 h-100' ></div>
                                        </div>
                                    </div>
                                    {/* section 2.3.9 */}
                                    <div className='row mb-3'>
                                        <div className='col-5 box  pl-5'>
                                            <div className='d-flex align-items-center mt-2'>
                                                <span className='form-sr-no pl-2'>2.3.9</span>
                                                <span className='text-align-justify'>Other (to be specified)</span>
                                            </div>
                                        </div>
                                        <div className='col-3 box p-0'>
                                            <div className=' border-all p-1 h-100' ></div>
                                        </div>
                                        <div className='col-3 p-0'>
                                            <div className=' border-all p-1 h-100' ></div>
                                        </div>
                                    </div>
                                    <div className='d-flex '>
                                        <i className='form-sr-no'>Note:</i>
                                        <i className='text-align-justify'>In case there are more than one class of shares carrying voting rights, the information regarding each such class shall be given separately.</i>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </PDFExport>
                    : <PDFExport
                        paperSize="A4"
                        margin="1cm"
                        scale={0.6}
                        fileName={`Form 20`}
                        pageTemplate={PageTemplate}
                        ref={pdfExportComponent}
                    >
                         <div style={{ fontFamily: `${fontFamilyForStaticData}`, fontSize: `${fontSizeForStaticData}`, fontWeight: 500 }}>
                            <FormName>
                                Form-20
                            </FormName>
                            <div className='row'>
                                <div className='col-md-12' >
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES ACT, 2017
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='d-flex justify-content-center'>
                                        THE COMPANIES REGULATIONS, 2024
                                    </HeadingWrapper>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{
                                        fontSize: '15px',
                                        // fontWeight: 'bold' 
                                    }} className='d-flex justify-content-center'>
                                        [Section 227(2)(f) and Regulation 30]
                                    </div>

                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-md-12'>
                                    <HeadingWrapper className='text-center d-flex justify-content-center'>
                                        PATTERN OF SHAREHOLDING
                                    </HeadingWrapper>

                                </div>
                            </div>

                            <PartsWrapper className='mt-4 mb-3'>
                                PART-I
                            </PartsWrapper>

                            <div className='row'>
                                <div className='col-md-12' style={{ padding: '25px 50px', wordWrap: 'break-word' }}>
                                    <div className='row'>

                                        <div className='col-12'>
                                            <em>(Please complete in typescript or in bold block capitals.)</em>
                                        </div>
                                        {/* section 1.1 */}
                                        <div className='col-12 mb-3'>
                                            <div className='row'>
                                                <div className='col-5 d-flex '><span className='form-sr-no mt-2'>1.1 </span>
                                                    <span className='mt-2'>Name of the Company</span>
                                                </div>
                                                <div className='col-7'>
                                                    <div style={{ minHeight: "35px" }} className="company-name border-all ml-1" ></div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* part II */}
                                        <PartsWrapper className='mt-4 mb-3'>
                                            PART-II
                                        </PartsWrapper>
                                        {/* <div className='col-12 mb-1'>
                    <h6 className='text-center'><u>PART-II</u></h6>
                </div> */}
                                        {/* section 2.1 */}
                                        <div className='col-12 mb-2'>
                                            <div className='row'>
                                                <div className='col-8'>
                                                    <div className='d-flex '>
                                                        <span className='form-sr-no'>2.1 </span>
                                                        <span className='word-spacing-10'>Pattern of holding of the shares held by the shareholders as at</span>
                                                    </div>
                                                </div>
                                                <div className='col-4 d-flex'>
                                                    <span className='d-flex ml-4 mr-2'>
                                                        <div className="form-20-date-cell text-center border-all"></div>
                                                        <div className="form-20-date-cell text-center border-all border-left-0"></div>
                                                    </span>
                                                    <span className='d-flex mx-2'>
                                                        <div className="form-20-date-cell text-center border-all"></div>
                                                        <div className="form-20-date-cell text-center border-all border-left-0"></div>
                                                    </span>
                                                    <span className='d-flex mx-2'>
                                                        <div className="form-20-date-cell text-center border-all"></div>
                                                        <div className="form-20-date-cell text-center border-all border-left-0"></div>
                                                        <div className="form-20-date-cell text-center border-all border-left-0"></div>
                                                        <div className="form-20-date-cell text-center border-all border-left-0" ></div>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* section 2.2 */}
                                    <div className='row mb-2'>
                                        <div className='col-3 box border border-dark '>
                                            <div className='d-flex'>
                                                <span className='form-sr-no'>2.2</span>
                                                <span>No of</span>
                                            </div>
                                            <span className='ml-3 pl-2'>shareholders</span>
                                        </div>
                                        <div className='col-5 box border border-dark text-center vertical-align-top '>
                                            <u>Shareholdings</u>
                                        </div>
                                        <div className='col-3 border border-dark text-center vertical-align-top '>
                                            <u>Total shares held</u>
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <div className='col-3 box p-0'>
                                            <div className=' border-all py-0 h-100'></div>
                                        </div>
                                        <div className='col-5 d-flex align-items-center box border border-dark '>
                                            <span>shareholding from 1 to 100 shares</span>
                                        </div>
                                        <div className='col-3 p-0'>
                                            <div className=' border-all py-0 h-100'></div>
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <div className='col-3 box p-0'>
                                            <div className='border-all py-0 h-100'></div>
                                        </div>
                                        <div className='col-5 d-flex align-items-center box border border-dark '>
                                            <span>shareholding from 101 to 500 shares</span>
                                        </div>
                                        <div className='col-3 p-0'>
                                            <div className='border-all py-0 h-100'></div>
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <div className='col-3 box p-0'>
                                            <div className='border-all py-0 h-100' ></div>
                                        </div>
                                        <div className='col-5 d-flex align-items-center box border border-dark '>
                                            <span>shareholding from 501 to 1000 shares</span>
                                        </div>
                                        <div className='col-3 p-0'>
                                            <div className='border-all py-0 h-100' ></div>
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <div className='col-3 box p-0'>
                                            <div className='border-all py-0 h-100' ></div>
                                        </div>
                                        <div className='col-5 d-flex align-items-center box border border-dark '>
                                            <span>shareholding from 1001 to 5000 shares</span>
                                        </div>
                                        <div className='col-3 p-0'>
                                            <div className='border-all py-0 h-100' ></div>
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <div className='col-3 box p-0'>
                                            <div className='border-all py-0 h-100' ></div>
                                        </div>
                                        <div className='col-5 box d-flex align-items-center box border border-dark '>
                                            <span>shareholding from 5001 to 10000 shares</span>
                                        </div>
                                        <div className='col-3 p-0'>
                                            <div className='border-all py-0 h-100' ></div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-3 box p-0'>
                                            <div className='border-all py-0 input-h-20' ></div>
                                        </div>
                                        <div className='col-5 box p-0'>
                                            <div className='border-all py-0 input-h-20' ></div>
                                        </div>
                                        <div className='col-3 p-0'>
                                            <div className='border-all py-0 input-h-20' ></div>
                                        </div>
                                        <div className='col-12 text-center'>
                                            <i className=''>(Add appropriate slabs of shareholdings)</i>
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <div className='col-3 box p-0'>
                                            <div className='border-all py-0 input-h-20' ></div>
                                        </div>
                                        <div className='col-5 d-flex box p-0'>
                                            <span className=' position-absolute pl-2'>Total</span>
                                            <div style={{width:"100%"}} className='border-all pl-5 py-0 input-h-20' ></div>
                                        </div>
                                        <div className='col-3 p-0'>
                                            <div className='border-all py-0 input-h-20' ></div>
                                        </div>
                                    </div>
                                    {/* section 2.3 */}
                                    <div className='row mb-2'>
                                        <div className='col-5 box border border-dark '>
                                            <div className='d-flex'>
                                                <span className='form-sr-no border-right border-dark pr-2'>2.3</span>
                                                <span>Categories of shareholders</span>
                                            </div>
                                        </div>
                                        <div className='col-3 box border border-dark text-center '>
                                            <span>share held</span>
                                        </div>
                                        <div className='col-3 border border-dark text-center '>
                                            <span>Percentage</span>
                                        </div>
                                    </div>
                                    {/* section 2.3.1 */}
                                    <div className='row mb-2'>
                                        <div className='col-5 box  pl-5'>
                                            <div className='d-flex'>
                                                <span className='form-sr-no pl-2'>2.3.1</span>
                                                <span className='text-align-justify'>Directors, Chief Executive Officer, and their spouse and minor children.</span>
                                            </div>
                                        </div>
                                        <div className='col-3 box p-0'>
                                            <div className=' border-all p-1 h-100'></div>
                                        </div>
                                        <div className='col-3 p-0'>
                                            <div className=' border-all p-1 h-100'></div>
                                        </div>
                                    </div>
                                    {/* section 2.3.2 */}
                                    <div className='row mb-2'>
                                        <div className='col-5 box  pl-5'>
                                            <div className='d-flex'>
                                                <span className='form-sr-no pl-2'>2.3.2</span>
                                                <span className='text-align-justify'>Associated Companies, undertakings and related parties.</span>
                                            </div>
                                        </div>
                                        <div className='col-3 box p-0'>
                                            <div className=' border-all p-1 h-100'></div>
                                        </div>
                                        <div className='col-3 p-0'>
                                            <div className=' border-all p-1 h-100'></div>
                                        </div>
                                    </div>
                                    {/* section 2.3.3 */}
                                    <div className='row mb-2'>
                                        <div className='col-5 box  pl-5'>
                                            <div className='d-flex align-items-center'>
                                                <span className='form-sr-no pl-2'>2.3.3</span>
                                                <span>NIT and ICP</span>
                                            </div>
                                        </div>
                                        <div className='col-3 box p-0'>
                                            <div className=' border-all p-1 h-100' ></div>
                                        </div>
                                        <div className='col-3 p-0'>
                                            <div className=' border-all p-1 h-100' ></div>
                                        </div>
                                    </div>
                                    {/* section 2.3.4 */}
                                    <div className='row mb-2'>
                                        <div className='col-5 box  pl-5'>
                                            <div className='d-flex'>
                                                <span className='form-sr-no pl-2'>2.3.4</span>
                                                <span className='text-align-justify'>Banks Development Financial Institutions, Non Banking Financial Institutions.</span>
                                            </div>
                                        </div>
                                        <div className='col-3 box p-0'>
                                            <div className=' border-all p-1 h-100'></div>
                                        </div>
                                        <div className='col-3 p-0'>
                                            <div className=' border-all p-1 h-100'></div>
                                        </div>
                                    </div>
                                    {/* section 2.3.5 */}
                                    <div className='row mb-2'>
                                        <div className='col-5 box  pl-5'>
                                            <div className='d-flex align-items-center'>
                                                <span className='form-sr-no pl-2'>2.3.5</span>
                                                <span>Insurance Companies</span>
                                            </div>
                                        </div>
                                        <div className='col-3 box p-0'>
                                            <div className=' border-all p-1 h-100' ></div>
                                        </div>
                                        <div className='col-3 p-0'>
                                            <div className=' border-all p-1 h-100' ></div>
                                        </div>
                                    </div>
                                    {/* section 2.3.6 */}
                                    <div className='row mb-2'>
                                        <div className='col-5 box pl-5'>
                                            <div className='d-flex align-items-center mt-2'>
                                                <span className='form-sr-no  pl-2'>2.3.6</span>
                                                <span className='text-align-justify '>Modarabas and Mutual Funds</span>
                                            </div>
                                        </div>
                                        <div className='col-3 box p-0'>
                                            <div className=' border-all p-1 h-100' ></div>
                                        </div>
                                        <div className='col-3 p-0'>
                                            <div className=' border-all p-1 h-100' ></div>
                                        </div>
                                    </div>
                                    {/* section 2.3.7 */}
                                    <div className='row mb-2'>
                                        <div className='col-5 box  pl-5'>
                                            <div className='d-flex align-items-center mt-2'>
                                                <span className='form-sr-no pl-2'>2.3.7</span>
                                                <span className='text-align-justify'>Share holders holding 10%</span>
                                            </div>
                                        </div>
                                        <div className='col-3 box p-0'>
                                            <div className=' border-all p-1 h-100' ></div>
                                        </div>
                                        <div className='col-3 p-0'>
                                            <div className=' border-all p-1 h-100' ></div>
                                        </div>
                                    </div>
                                    {/* section 2.3.8 */}
                                    <div className='row mb-2'>
                                        <div className='col-12 box  pl-5'>
                                            <div className='d-flex align-items-center'>
                                                <span className='form-sr-no pl-2'>2.3.8</span>
                                                <span>Genral Public</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <div className='col-5 box  pl-5'>
                                            <div className='d-flex align-items-center pl-5 mt-2'>
                                                <span className='form-sr-no pl-5'>a.</span>
                                                <span className='text-align-justify'>Local</span>
                                            </div>
                                        </div>
                                        <div className='col-3 box p-0'>
                                            <div className=' border-all p-1 h-100' ></div>
                                        </div>
                                        <div className='col-3 p-0'>
                                            <div className=' border-all p-1 h-100' ></div>
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <div className='col-5 box  pl-5'>
                                            <div className='d-flex align-items-center pl-5 mt-2'>
                                                <span className='form-sr-no pl-5'>b.</span>
                                                <span className='text-align-justify'>Foreign</span>
                                            </div>
                                        </div>
                                        <div className='col-3 box p-0'>
                                        <div className=' border-all p-1 h-100' ></div>
                                        </div>
                                        <div className='col-3 p-0'>
                                        <div className=' border-all p-1 h-100' ></div>
                                        </div>
                                    </div>
                                    {/* section 2.3.9 */}
                                    <div className='row mb-3'>
                                        <div className='col-5 box  pl-5'>
                                            <div className='d-flex align-items-center mt-2'>
                                                <span className='form-sr-no pl-2'>2.3.9</span>
                                                <span className='text-align-justify'>Other (to be specified)</span>
                                            </div>
                                        </div>
                                        <div className='col-3 box p-0'>
                                            <div className=' border-all p-1 h-100' ></div>
                                        </div>
                                        <div className='col-3 p-0'>
                                            <div className=' border-all p-1 h-100' ></div>
                                        </div>
                                    </div>
                                    <div className='d-flex '>
                                        <i className='form-sr-no'>Note:</i>
                                        <i className='text-align-justify'>In case there are more than one class of shares carrying voting rights, the information regarding each such class shall be given separately.</i>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </PDFExport>}
            </div>
            <button disabled={loading} className='btn btn-danger' onClick={(e) => {
                // if(viewPdf)
                //   SetViewPDf(true)
                if (pdfExportComponent.current) {
                    pdfExportComponent.current.save();
                }
            }}>Download PDF</button>
        </div>
    )
}
const PartsWrapper = styled.div`
color: #121212;
text-decoration: underline;
justify-content: center;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const ScrollWrapper = styled.div`
max-height: 50px;
overflow-y: scroll;
overflow-x: hidden;
::-webkit-scrollbar{
    height: 5px;
    width: 3px;
  }
  
  ::-webkit-scrollbar-track{
    background: #F9F9FB;
  }
  ::-webkit-scrollbar-thumb{
    background: #4E515680;
    border-radius: 5px;
  
  }
`;
const HeadingWrapper = styled.div`
font-size: 17px;
font-weight: bold;
`;
const FormName = styled.div`
color: #121212;
text-decoration: underline;
justify-content: end;
display: flex;
margin-right: 15px;
font-size: 17px;
font-weight: bold;
`;
const Wrapper = styled.div`
table.table-bordered{
    border: 1px solid #121212
     border-color: #121212; 
    // border:1px solid blue;
    // margin-top:20px;
  }
// table.table-bordered > thead > tr > th{
//     // border:1px solid blue;
//     border-color: #121212; 
// }
// table.table-bordered > tbody > tr > td{
//     // border:1px solid blue;
//     borderC-color: #121212; 
// }
 tr td {
    // border:1px solid blue;.
    border-color: #121212; 
}

`;
const WrapperDashed = styled.div`

  td {
    
    border: 1px dashed #dddcdc;
}

`;